import React, { useEffect, useState } from 'react'; // Import useState for managing state
import KanbanBoard from 'react-custom-kanban-board';
import Sidebar from '../../../Components/Siderbar';
import Header from '../../../Components/Header';
import { IoSearch } from 'react-icons/io5';
import './App.css';
import { IoChevronDownOutline } from 'react-icons/io5';
import axios from 'axios';
import { getCookie, base64_decode } from '../../../func';
import { RxDotFilled } from "react-icons/rx";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import avatar1 from '../../../Images/profile/avatar-1.jpg'
import avatar2 from '../../../Images/profile/avatar-3.jpg'
import avatar3 from '../../../Images/profile/avatar-2.jpg'
import { SlCalender } from "react-icons/sl";
import { RiCheckDoubleFill } from "react-icons/ri";
import { BiCommentDetail } from "react-icons/bi";
import { useNavigate, useParams } from 'react-router-dom';
import { LuTrash2 } from "react-icons/lu";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineFilePresent } from "react-icons/md"
import Footer from '../../../Components/Footer';
import { Link } from 'react-router-dom';

const columns = [
    { title: "Pending", key: "pending" },
    { title: "In Progress", key: "in_progress" },
    { title: "Done", key: "completed" },
];

const renderCard = (card, userId, handleProjectDetails, handleEditTaskDetails, handleDragStart,) => {

    // const [taskDetail, setTaskDetail] = useState(null);

    // // console.log(card, "From Render Card")

    // const getTaskDetails = async () => {
    //     try {
    //         const json = JSON.stringify({ "task_id": Number(card.id), });
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_task_details`, JSON.stringify({ params: json }), {
    //             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //         });

    //         if (response.data.success === "true") {
    //             // Handle the project details including tasks
    //             // console.log(response.data);
    //             // return response.data;
    //         }
    //     } catch (error) {
    //         console.error(`Error: ${error}`);
    //     }
    // };

    // const [selectedCard, setSelectedCard] = useState(null);

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
        return color;
    };

    // const handleProjectDetails = async (card) => {
    //     try {
    //         if (card) {
    //             // Set the card data to a state variable if needed
    //             setSelectedCard(card); // Assuming you have a state variable named selectedCard

    //             // Trigger the modal
    //             setTimeout(() => {
    //                 document.querySelector('#taskDetailModalTrigger').click();
    //             }, 0);
    //         } else {
    //             console.error("Project details not found");
    //         }
    //     } catch (error) {
    //         console.error("Error fetching project details:", error);
    //     }
    // };

    const formatProjectId = (id) => {
        return id < 10 ? `0${id}` : id;
    };

    const extractFileName = (filePath) => {
        // Extract the file name
        const fileName = filePath.split('/').pop();

        // Remove everything before the first underscore, including numbers and dots
        return fileName.replace(/^[^_]*_/, '');
    };

    const downloadFile = (filePath) => {
        const fileName = extractFileName(filePath);
        const link = document.createElement('a');
        const filePathNew = process.env.REACT_APP_BASE_API + filePath
        // console.log(filePathNew)
        link.href = filePathNew;
        link.download = fileName;
        link.click();
    };

    // console.log(card)

    // // console.log(userId, "From Render Card User Id")

    // // console.log(card.id)

    return (
        <>
            <div draggable onDragStart={(e) => handleDragStart(e, card)} style={{ margin: "8px 0", transition: "transform 0.2s", }} onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")} onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")} >
                <div className="">
                    <div className="bg-white rounded-3 p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <div className="">
                                    <h5 className="login-label-text-css mb-0">#PM00{card.projectId} - T{card.id} </h5>
                                </div>
                            </div>
                            <div>
                                <div className="dropdown">
                                    <button
                                        className="border-0 bg-transparent dropdown-toggle project-add-modal-dropdown"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <BiDotsHorizontalRounded />
                                    </button>
                                    <ul className="dropdown-menu">
                                        {/* {isAdmin &&
                                        <li>
                                            <div className='dropdown-item'>
                                                <button type="button" className="border-0 text-start text-muted bg-transparent p-0" onClick={() => handleEditProject(project)}>
                                                    Edit Project
                                                </button>
                                                <button id="editModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectEditModal"></button>
                                            </div>
                                        </li>
                                    } */}
                                        {/* {userId !== 0 && (userId === '1' || userId === card.assignedTo) ? ( */}
                                        <li>
                                            <div className="dropdown-item">
                                                <button
                                                    type="button"
                                                    disabled={userId !== 0 && (userId === '1' || userId === card.assignedTo) ? false : true}
                                                    className={`border-0 w-100 ${userId !== 0 && (userId === '1' || userId === card.assignedTo) ? '' : 'disabled'} text-start text-muted bg-transparent p-0`}
                                                    onClick={() => handleEditTaskDetails(card)}
                                                >
                                                    Edit Task
                                                </button>
                                                <button
                                                    id="editTaskDetailModalTrigger"
                                                    type="button"
                                                    className="d-none"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editTaskDetailModal"
                                                ></button>
                                            </div>
                                            <div className="dropdown-item">
                                                <Link className='text-start text-muted bg-transparent text-decoration-none' to={`/activity/${card.projectId}/${card.id}`}>Task Activites</Link>
                                            </div>
                                        </li>
                                        {/* ) : null} */}
                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 d-flex align-items-center">
                            <div className="">
                                <h5 className='d-flex login-label-text-css align-items-center'>
                                    <SlCalender className='me-2' /> {new Date(card.createdAt).toLocaleDateString()}
                                </h5>
                            </div>
                        </div>
                        <div className="mt-2">
                            <div className="mb-1 d-flex">
                                {/* <p className="fw-bold text-start mb-0 text-muted">{card.title}</p> */}
                                <button
                                    type="button"
                                    className="border-0 fw-bold text-start mb-0 text-muted bg-transparent p-0 project-title-css"
                                    onClick={() => handleProjectDetails(card)}
                                >
                                    {card.title}
                                </button>
                                <button id="taskDetailModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#taskDetailModal"></button>
                            </div>
                            <div>
                                <p className="text-start text-muted project-desc-css">{card.description}</p>
                            </div>
                        </div>
                        <div className="my-3">
                            {/* <div className="progress progress-sm animated-progess mb-3" style={{ height: '4px' }}>
                        <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} value={0} aria-valuemin={0} aria-valuemax={100} />
                    </div> */}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="position-relative">
                                <div className="d-flex profile-image-stack">
                                    <div className="">
                                        {/* <img className="project-member-profile-pic-css" src={avatar1} alt="Avatar 1" /> */}
                                        {card.assignedUserPhoto ? (
                                            <Link to={`/profile/${encodeURIComponent(btoa(btoa(card.assignedTo)))}`}>
                                                <img style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} className="me-2" src={process.env.REACT_APP_BASE_API + card.assignedUserPhoto} alt={card.assignedUserName} />
                                            </Link>
                                        ) : (
                                            <Link to={`/profile/${encodeURIComponent(btoa(btoa(card.assignedTo)))}`}>
                                                <div
                                                    style={{
                                                        backgroundColor: getColorFromHash(card.assignedUserName || card.assignedTo.toString()),
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        objectFit: 'cover',
                                                        fontSize: '12px',
                                                    }}
                                                    className='me-2'
                                                >
                                                    {card.assignedUserName.charAt(0).toUpperCase()}
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span
                                    className={`badge p-2 ${card.status === 'in_progress'
                                        ? 'bg-warning-subtle text-warning'
                                        : card.status === 'completed' ? 'bg-success-subtle text-success'
                                            : 'bg-danger-subtle text-danger'
                                        }`}
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    <small>{card.status === 'completed' ? 'Completed' : card.status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const RenderAddCard = (column, setCards) => {
    const params = useParams();
    const [users, setUsers] = useState([]);
    const [userIdToAssign, setUserIdToAssign] = useState(null);
    const [priority, setPriority] = useState("Select Priority");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isRequested, setIsRequested] = useState(false);

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
        return color;
    };

    const handleFileChange = (e) => {
        setSelectedFiles([...e.target.files]); // Fixed to update selectedFiles
    };

    const handleRemoveFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file at index
    };

    // Update handleUserSelect to enforce single selection
    const handleUserSelect = (id) => {
        setUserIdToAssign(id); // Replace the previously selected user with the new one
        const inputs = document.querySelectorAll('.form-check-input'); // Get all checkboxes
        inputs.forEach((input) => {
            input.checked = parseInt(input.value, 10) === id; // Keep only the new selection checked
        });
    };

    const cookieValue = getCookie("token");
    const creatorName = cookieValue ? cookieValue.split("|")[1] : null;

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        const json = JSON.stringify({ "offset": 0, "limit": 1000, "authorization": getCookie("token") });
        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_users`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            if (response.data.success === "true") {
                // Assign color once based on user ID or name
                const usersWithColor = response.data.users.map(user => ({
                    ...user,
                    color: getColorFromHash(user.name || user.id.toString())
                }));
                setUsers(usersWithColor);
                // console.log(users)
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    };

    const addProject = async (projectName, projectDesc, priority) => {
        setIsRequested(true);
        // const userIdsToSend = selectedUserIds.length > 0 ? selectedUserIds : [userId];
        const json = JSON.stringify({
            name: projectName,
            description: projectDesc,
            project_id: atob(atob(params.projectId)),
            assigned_to: userIdToAssign,
            status: 'pending',
            priority: priority,
            created_at: new Date().toISOString(),
            created_by: creatorName, "authorization": getCookie("token")
        });
        const formData = new FormData();
        formData.append("params", JSON.stringify(json));

        // Append multiple files
        if (selectedFiles && selectedFiles.length > 0) {
            selectedFiles.forEach((file, index) => {
                formData.append(`files[]`, file); // `file_0`, `file_1`, etc.
            });
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_task`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.success === "true") {
                const newTask = {
                    // projectId: atob(atob(params.projectId)),
                    // id: response.data.new_task.id.toString(),
                    // title: response.data.new_task.name,
                    // status: response.data.new_task.status,
                    // description: response.data.new_task.description,
                    // assignedTo: response.data.new_task.assigned_to,
                    // assignedUserName: response.data.new_task.assigned_user_name,
                    // assignedUserEmail: response.data.new_task.assigned_user_email,
                    // assignedUserPhoto: response.data.new_task.assigned_user_photo,
                    // createdAt: response.data.new_task.created_at,
                    // files: response.data.files
                    projectId: atob(atob(params.projectId)),
                    id: response.data.new_task.id.toString(),
                    title: response.data.new_task.name,
                    status: response.data.new_task.status,
                    priority: response.data.new_task.priority,
                    description: response.data.new_task.description,
                    assignedTo: response.data.new_task.assigned_to,
                    assignedUserName: response.data.new_task.assigned_user_name,
                    assignedUserEmail: response.data.new_task.assigned_user_email,
                    assignedUserPhoto: response.data.new_task.assigned_user_photo,
                    createdAt: response.data.new_task.created_at,
                    files: response.data.files,
                };
                setCards((prevCards) => [...prevCards, newTask]);
                resetProjectModal();
                resetAllFields();
                resetInputs()
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        } finally {
            setIsRequested(false);
        }
    };

    const resetProjectModal = () => {
        document.querySelector('#addModalCloseBtn').click();
        setPriority('Select Priority');
    };

    const resetInputs = () => {
        // Reset file input
        setSelectedFiles([]);
        // setEditFiles([]);

        // Reset checkboxes
        const inputs = document.querySelectorAll('.form-check-input');
        inputs.forEach((input) => {
            input.checked = false;
        });

        // Reset userIdToAssign and selectedEditUserId
        setUserIdToAssign(null);
        // setSelectedEditUserId(null);
    };

    const resetAllFields = () => {
        setPriority('Select Priority');
        setSelectedFiles([]);

        const projectNameInput = document.querySelector('#projectName');
        const projectDescInput = document.querySelector('#projectDesc');
        const dueDateInput = document.querySelector('#dueDate');
        setUserIdToAssign(null);

        if (projectNameInput) {
            projectNameInput.value = '';
        }
        if (projectDescInput) {
            projectDescInput.value = '';
        }
        if (dueDateInput) {
            dueDateInput.value = '';
        }
    };

    return (
        <>


            <div className="modal roboto-regular fade" id="addTaskModal" tabIndex={-1} aria-labelledby="addTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addTaskModalLabel">Add Task</h1>
                            <button id='addModalCloseBtn' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {/* Project Name Input */}
                            <div className="mt-2">
                                <h5 className="login-label-text-css text-start mb-2">Task Name</h5>
                                <input id="projectName" className="w-100 border focus-visible-none p-2 px-3 rounded-2 bg-transparent" type="text" />
                            </div>

                            {/* Project Description Input */}
                            <div className="mt-4">
                                <h5 className="login-label-text-css text-start mb-2">Task Description</h5>
                                <textarea id="projectDesc" style={{ resize: 'none' }} className="w-100 border focus-visible-none p-3 rounded-2 bg-transparent" />
                            </div>

                            {/* Due Date and Categories */}
                            <div className="mt-4 d-flex justify-content-between gap-4">
                                {/* <div className="w-100">
                                    <h5 className="login-label-text-css text-start mb-2">Due Date</h5>
                                    <input id="dueDate" className="w-100 border focus-visible-none p-2 px-3 rounded-2 bg-transparent" type="date" />
                                </div> */}

                                {/* <div className="w-100">
                                    <h5 className="login-label-text-css text-start mb-2">Categories</h5>
                                    <div className="dropdown">
                                        <button
                                            className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {selectedCategory}
                                            <IoChevronDownOutline />
                                        </button>

                                        <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                            {categories.map((category) => (
                                                <li key={category.id}>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCategorySelect(category);
                                                        }}
                                                    >
                                                        {category.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                </div> */}
                                <div className="w-100">
                                    <h5 className="login-label-text-css text-start mb-2">Priority</h5>
                                    <div className="dropdown">
                                        <button className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {priority}
                                            <IoChevronDownOutline />
                                        </button>
                                        <ul className="dropdown-menu">
                                            {['High', 'Medium', 'Low'].map((level) => (
                                                <li key={level}>
                                                    <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); setPriority(level); }}>
                                                        {level}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 w-100">
                                <div className="mb-3">
                                    <h5 className="login-label-text-css text-start mb-2">Upload Files</h5>
                                    <input
                                        type="file"
                                        id="fileUpload"
                                        className="form-control"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div>

                                {selectedFiles.length > 0 && (
                                    <div className="mt-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className='text-start'>
                                                    <th>S.No</th>
                                                    <th>Filename</th>
                                                    {/* <th>Type</th> */}
                                                    <th>Size (KB)</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedFiles.map((file, index) => (
                                                    <tr key={index} className='text-start'>
                                                        <td>{index + 1}</td>
                                                        <td className='text-start'>{file.name}</td>
                                                        {/* <td>{file.type || 'Unknown'}</td> */}
                                                        <td>{(file.size / 1024).toFixed(2)}</td>
                                                        <td>
                                                            <button
                                                                className="border-0 bg-transparent"
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                <LuTrash2 className='text-danger' />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </div>

                            {/* Progress Slider */}
                            {/* <div className="mt-4">
                                <h5 className="login-label-text-css text-start mb-2">Project Progress</h5>
                                <div className="d-flex align-items-center gap-3">
                                    <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={progress}
                                        onChange={handleProgressChange}
                                        className="form-range w-100"
                                    />
                                    <span>{progress}%</span>
                                </div>
                            </div> */}
                            <div className="mt-4">
                                <h5 className="login-label-text-css text-start mb-3">Add Team Member</h5>
                                <div style={{ maxHeight: '171px', overflowY: 'auto' }} className="align-items-center gap-3">
                                    {/* {users.map((user) => (
                                        <div className="form-check d-flex align-items-center" key={user.id}>
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                value={user.id}
                                                id={`flexCheckDefault${user.id}`}
                                                onChange={() => handleUserSelect(user.id)}
                                            />
                                            <label className="form-check-label d-flex login-label-text-css" htmlFor={`flexCheckDefault${user.id}`}>
                                                <img src={user.profile_picture} alt="" />
                                                <h5 className='login-label-text-css text-start mb-0'> {user.name} </h5>
                                            </label>
                                        </div>
                                    ))} */}
                                    {users.map((user) => (
                                        <div className="form-check d-flex align-items-center mb-3" key={user.id}>
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                value={user.id}
                                                id={`flexCheckDefault${user.id}`}
                                                onChange={() => handleUserSelect(user.id)}
                                            />
                                            <label
                                                className="form-check-label d-flex login-label-text-css align-items-center"
                                                htmlFor={`flexCheckDefault${user.id}`}
                                            >
                                                {user.profile_picture ? (
                                                    <img style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} className="me-2" src={process.env.REACT_APP_BASE_API + user.profile_picture} alt={user.name} />
                                                ) : (
                                                    <div
                                                        style={{
                                                            backgroundColor: getColorFromHash(user.name || user.id.toString()),
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            objectFit: 'cover',
                                                            fontSize: '12px',
                                                        }}
                                                        className='me-2'
                                                    >
                                                        {user.name.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                                <h5 className="login-label-text-css text-start mb-0">{user.name}</h5>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            {/* <button
                                type="button"
                                className="btn btn-primary"
                                disabled={isRequested}
                                onClick={() => {
                                    const projectName = document.querySelector('#projectName').value;
                                    const projectDesc = document.querySelector('#projectDesc').value;
                                    const dueDate = document.querySelector('#dueDate').value;
                                    const categoryId = categories.find(cat => cat.name === selectedCategory)?.id || '';
                                    addProject(projectName, projectDesc, dueDate, categoryId, progress);
                                }}
                            >
                                {isRequested ? 'Adding Task...' : " Add Task"}
                            </button> */}

                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={isRequested}
                                onClick={() => {
                                    const projectName = document.querySelector('#projectName').value;
                                    const projectDesc = document.querySelector('#projectDesc').value;
                                    addProject(projectName, projectDesc, priority); // Updated to use correct params
                                }}
                            >
                                {isRequested ? 'Adding Task...' : 'Add Task'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ margin: "16px 0", padding: "0px 16px", position: 'absolute', bottom: "0px", left: "0px", background: "#f4f4f4", width: "100%" }} className='pt-3'>
                <button type="button" data-bs-toggle="modal" data-bs-target="#addTaskModal" className='w-100 btn btn-primary'>
                    Add Task
                </button>
            </div>
        </>
    )
};

const AllTasks = () => {
    const [newBoardName, setNewBoardName] = useState('');
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [boards, setBoards] = useState(columns);
    const [cards, setCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [editCardDetails, setEditCardDetails] = useState(null);
    const [userId, setUserId] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false)
    const [priority, setPriority] = useState('Select Priority');
    const [editingPriority, setEditingPriority] = useState(editCardDetails?.priority);
    const [users, setUsers] = useState([]);
    const [selectedEditUserId, setSelectedEditUserId] = useState(editCardDetails?.assignedTo || null);
    const [editFiles, setEditFiles] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const [editingStatus, setEditingStatus] = useState(editCardDetails?.status || 'Select Status');
    const [taskActivities, setTaskActivities] = useState([]);
    const [projectExist, setProjectExist] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const token = getCookie('token')
        if (!token || token === undefined || token === null) {
            navigate('/', { replace: true })
        } else {
            // getProjectDetails(atob(atob(params.projectId)), atob(params.creatorId))
            //user is already loggedin
            setUserId(base64_decode(getCookie("token")).split('|')[0]);
            // // console.log(base64_decode(getCookie("token")).split('|'))
            setIsAdmin(base64_decode(getCookie("token")).split('|')[3] === "admin" ? true : false)
            getTasks();
            getUsers();
        }
    }, [])

    useEffect(() => {
        if (editCardDetails) {
            setSelectedEditUserId(editCardDetails.assignedTo || null);
            setEditingStatus(editCardDetails.status || 'Select Status');
            setEditingPriority(editCardDetails.priority);
        }
    }, [editCardDetails]);

    const handleEditUserSelect = (userId) => {
        setSelectedEditUserId(userId);
    };

    const handleEditFileChange = (e) => {
        const files = Array.from(e.target.files);
        const newFiles = files.filter((file) => {
            return !editFiles.some((editFile) => editFile.name === file.name && editFile.size === file.size);
        });
        setEditFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleRemoveEditAttachment = (index) => {
        setEditFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleRemoveAttachment = (fileId) => {
        setRemovedFiles((prevRemovedFiles) => [...prevRemovedFiles, fileId]);
        setEditCardDetails((prevDetails) => ({
            ...prevDetails,
            files: prevDetails.files.filter(file => file.file_id !== fileId)
        }));
    };

    const handleEditTaskDetails = (card) => {
        setEditCardDetails(card);
        setTimeout(() => {
            // console.log(card, "handleEditTaskDetails")
            document.querySelector('#editTaskDetailModalTrigger').click();
            // console.log(editCardDetails, "Edit Card Details")
            // console.log(editCardDetails, "Edit Card Details")
        }, 0);
    };

    // const saveTaskChanges = async () => {
    //     try {
    //         // Check if priority has changed
    //         if (editingPriority !== editCardDetails.priority) {
    //             const taskPriorityJson = JSON.stringify({
    //                 "project_id": atob(atob(params.projectId)),
    //                 "task_id": editCardDetails.id,
    //                 "priority": editingPriority, "authorization": getCookie("token")
    //             });
    //             await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_task_priority`, JSON.stringify({ params: taskPriorityJson }), {
    //                 headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //             });
    //         }

    //         // Check if status has changed
    //         if (editingStatus !== editCardDetails.status) {
    //             const updateStatusJson = JSON.stringify({
    //                 "task_id": editCardDetails.id,
    //                 "status": editingStatus, "authorization": getCookie("token")
    //             });
    //             await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_task_status`, JSON.stringify({ params: updateStatusJson }), {
    //                 headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //             });
    //         }

    //         // Check if assigned user has changed
    //         const currentAssignedUserId = editCardDetails.assignedTo;
    //         if (selectedEditUserId && selectedEditUserId !== currentAssignedUserId) {
    //             if (currentAssignedUserId) {
    //                 const resignJson = JSON.stringify({
    //                     "task_id": editCardDetails.id,
    //                     "user_id": currentAssignedUserId, "authorization": getCookie("token")
    //                 });
    //                 await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=resign_user_task`, JSON.stringify({ params: resignJson }), {
    //                     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //                 });
    //             }
    //             const assignJson = JSON.stringify({
    //                 "task_id": editCardDetails.id,
    //                 "user_id": selectedEditUserId, "authorization": getCookie("token")
    //             });
    //             await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=assign_task`, JSON.stringify({ params: assignJson }), {
    //                 headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //             });
    //         }

    //         // Check if there are new files to add
    //         if (editFiles.length > 0) {
    //             const addFilesJson = JSON.stringify({ "task_id": editCardDetails.id, "authorization": getCookie("token") });
    //             const formData = new FormData();
    //             formData.append("params", JSON.stringify(addFilesJson));
    //             editFiles.forEach((file) => {
    //                 formData.append("files[]", file);
    //             });
    //             await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_file_to_task`, formData, {
    //                 headers: { 'Content-Type': 'multipart/form-data' }
    //             });
    //         }

    //         // Check if there are files to remove
    //         if (removedFiles.length > 0) {
    //             for (const fileId of removedFiles) {
    //                 const removeFileJson = JSON.stringify({
    //                     "task_id": editCardDetails.id,
    //                     "file_id": fileId, "authorization": getCookie("token")
    //                 });
    //                 await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=remove_file_from_task`, JSON.stringify({ params: removeFileJson }), {
    //                     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //                 });
    //             }
    //         }

    //         // Fetch updated task details
    //         const taskDetailsJson = JSON.stringify({ "task_id": editCardDetails.id, "authorization": getCookie("token") });
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_task_details`, JSON.stringify({ params: taskDetailsJson }), {
    //             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //         });

    //         if (response.data.success === "true" && response.data.task) {
    //             const updatedTask = response.data.task;
    //             const fetchedTask = {
    //                 projectId: atob(atob(params.projectId)),
    //                 id: updatedTask.id.toString(),
    //                 title: updatedTask.task_title,
    //                 status: updatedTask.task_status,
    //                 priority: updatedTask.task_priority,
    //                 description: updatedTask.task_description,
    //                 assignedTo: updatedTask.assignee_id,
    //                 assignedUserName: updatedTask.assignee_name,
    //                 assignedUserEmail: updatedTask.assignee_email,
    //                 assignedUserPhoto: updatedTask.assignee_photo,
    //                 createdAt: updatedTask.created_at,
    //                 files: updatedTask.files,
    //             };
    //             setCards((prevCards) => prevCards.map((card) => (card.id === fetchedTask.id ? fetchedTask : card)));
    //             console.log(cards)
    //             setEditCardDetails(null);
    //             const modalCloseButton = document.getElementById('editTaskDetailModalClose');
    //             if (modalCloseButton) {
    //                 modalCloseButton.click();
    //             } else {
    //                 console.error("Modal close button not found.");
    //             }
    //         } else {
    //             console.error("Failed to update task details. Response:", response.data);
    //         }
    //     } catch (error) {
    //         console.error("Error updating task:", error);
    //     }
    // };

    // Update the saveTaskChanges function
    const saveTaskChanges = async () => {
        try {
            // Update priority if changed
            if (editingPriority !== editCardDetails.priority) {
                const taskPriorityJson = JSON.stringify({
                    "project_id": atob(atob(params.projectId)),
                    "task_id": editCardDetails.id,
                    "priority": editingPriority,
                    "authorization": getCookie("token")
                });
                await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_task_priority`, JSON.stringify({ params: taskPriorityJson }), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });
            }

            // Update status if changed
            if (editingStatus !== editCardDetails.status) {
                const updateStatusJson = JSON.stringify({
                    "task_id": editCardDetails.id,
                    "status": editingStatus,
                    "authorization": getCookie("token")
                });
                await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_task_status`, JSON.stringify({ params: updateStatusJson }), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });
            }

            // Update assigned user if changed
            const currentAssignedUserId = editCardDetails.assignedTo;
            if (selectedEditUserId && selectedEditUserId !== currentAssignedUserId) {
                if (currentAssignedUserId) {
                    const resignJson = JSON.stringify({
                        "task_id": editCardDetails.id,
                        "user_id": currentAssignedUserId,
                        "authorization": getCookie("token")
                    });
                    await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=resign_user_task`, JSON.stringify({ params: resignJson }), {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    });
                }
                const assignJson = JSON.stringify({
                    "task_id": editCardDetails.id,
                    "user_id": selectedEditUserId,
                    "authorization": getCookie("token")
                });
                await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=assign_task`, JSON.stringify({ params: assignJson }), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });
            }

            // Add new files if any
            if (editFiles.length > 0) {
                const addFilesJson = JSON.stringify({
                    "task_id": editCardDetails.id,
                    "authorization": getCookie("token")
                });
                const formData = new FormData();
                formData.append("params", JSON.stringify(addFilesJson));
                editFiles.forEach((file) => {
                    formData.append("files[]", file);
                });
                await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_file_to_task`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }

            // Remove files if any
            if (removedFiles.length > 0) {
                for (const fileId of removedFiles) {
                    const removeFileJson = JSON.stringify({
                        "task_id": editCardDetails.id,
                        "file_id": fileId,
                        "authorization": getCookie("token")
                    });
                    await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=remove_file_from_task`, JSON.stringify({ params: removeFileJson }), {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    });
                }
            }

            // Fetch updated task details
            const taskDetailsJson = JSON.stringify({
                "task_id": editCardDetails.id,
                "authorization": getCookie("token")
            });
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_task_details`, JSON.stringify({ params: taskDetailsJson }), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });

            if (response.data.success === "true" && response.data.task) {
                const updatedTask = response.data.task;
                const fetchedTask = {
                    projectId: atob(atob(params.projectId)),
                    id: updatedTask.id.toString(),
                    title: updatedTask.task_title,
                    status: updatedTask.task_status,
                    priority: updatedTask.task_priority,
                    description: updatedTask.task_description,
                    assignedTo: updatedTask.assignee_id,
                    assignedUserName: updatedTask.assignee_name,
                    assignedUserEmail: updatedTask.assignee_email,
                    assignedUserPhoto: updatedTask.assignee_photo,
                    createdAt: updatedTask.created_at,
                    files: updatedTask.files,
                };

                // Update the cards state with the updated task
                // setCards((prevCards) => prevCards.map((card) => (card.id === fetchedTask.id ? fetchedTask : card)));
                // Update the cards state with the updated task
                setCards((prevCards) => {
                    const taskExists = prevCards.some((card) => card.id === fetchedTask.id);
                    if (taskExists) {
                        // Update the existing task
                        return prevCards.map((card) => (card.id === fetchedTask.id ? fetchedTask : card));
                    } else {
                        // Add the new task to the end
                        return [...prevCards, fetchedTask];
                    }
                });
                // console.log(fetchedTask, cards)
                // Close the modal
                setEditCardDetails(null);
                const modalCloseButton = document.getElementById('editTaskDetailModalClose');
                if (modalCloseButton) {
                    modalCloseButton.click();
                } else {
                    console.error("Modal close button not found.");
                }
            } else {
                console.error("Failed to update task details. Response:", response.data);
            }
        } catch (error) {
            console.error("Error updating task:", error);
        }
    };

    const fetchProjectActivity = async (projectId, taskId) => {
        try {
            const json = JSON.stringify({ "project_id": projectId, "task_id": taskId, "authorization": getCookie("token") });
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_API}/api.php?action=get_activities`,
                JSON.stringify({ params: json }),
                {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }
            );

            if (response.data.success === "true") {
                // Assuming each activity has a `timestamp` property
                const sortedActivities = response.data.activities.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                setTaskActivities(sortedActivities);
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    // const handleCardDrop = async (cardId, newStatus) => {
    //     try {

    //         // console.log(cardId, typeof (cardId), newStatus, typeof (newStatus));

    //         // Prepare the JSON payload for updating the task status
    //         const updateStatusJson = JSON.stringify({
    //             "task_id": cardId,
    //             "status": newStatus
    //         });

    //         // Make an API request to update the task status
    //         await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_task_status`, JSON.stringify({ params: updateStatusJson }), {
    //             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //         });

    //         // const cardId = cardId

    //         // Update the card status in the local state
    //         setCards((prevCards) =>
    //             prevCards.map((card) =>
    //                 card.id === cardId ? { ...card, status: newStatus } : card
    //             )
    //         );
    //     } catch (error) {
    //         console.error("Error updating card status:", error);
    //     }
    // };

    const handleCardDrop = async (cardId, newStatus) => {
        try {
            // Update the status of the card on the server
            const updateStatusJson = JSON.stringify({ "task_id": cardId, "status": newStatus, "authorization": getCookie("token") });
            await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_task_status`, JSON.stringify({ params: updateStatusJson }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

            // Update the local state to reflect the new status of the card
            setCards((prevCards) => {
                return prevCards.map((card) =>
                    card.id === cardId ? { ...card, status: newStatus } : card
                );
            });
        } catch (error) {
            console.error("Error updating card status:", error);
        }
    };

    const getUsers = () => {
        const json = JSON.stringify({ "offset": 0, "limit": 1000, "authorization": getCookie("token") });
        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_users`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            if (response.data.success === "true") {
                // Assign color once based on user ID or name
                const usersWithColor = response.data.users.map(user => ({
                    ...user,
                    color: getColorFromHash(user.name || user.id.toString())
                }));
                setUsers(usersWithColor);
                // console.log(users)
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    };

    // useEffect(() => {
    //     if (!projectExist) {
    //         navigate('/projects')
    //     }
    // }, [projectExist])

    const getTasks = () => {
        const json = JSON.stringify({
            "project_id": atob(atob(params.projectId)),
            "offset": 0,
            "limit": 1000, "authorization": getCookie("token")
        });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_tasks`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            if (response.data.success === "true") {
                // Map the fetched tasks to include all details
                const fetchedTasks = response.data.tasks.map(task => ({
                    projectId: atob(atob(params.projectId)),
                    id: task.id.toString(),
                    title: task.name,
                    status: task.status,
                    priority: task.priority,
                    description: task.description,
                    assignedTo: task.assigned_to,
                    assignedUserName: task.assigned_user_name,
                    assignedUserEmail: task.assigned_user_email,
                    assignedUserPhoto: task.assigned_user_photo,
                    createdAt: task.created_at,
                    files: task.files
                }));
                setCards(fetchedTasks);
            } else if (response.data.message === "No tasks found for the specified project") {
                setCards([]);
            } else {
                navigate('/projects')
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            setCards([]); // Set to empty on error
        });
    };

    // const getProjectDetails = async (projectId, creatorId) => {
    //     try {
    //         // console.log(projectId);
    //         const json = JSON.stringify({ "id": creatorId, "project_id": projectId, "authorization": getCookie("token") });
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_project_details`, JSON.stringify({ params: json }), {
    //             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //         });

    //         if (response.data.success === "true") {
    //             setProjectExist(true);
    //         }
    //     } catch (error) {
    //         console.error(`Error: ${error}`);
    //     }
    // };

    const handleProjectDetails = async (card) => {
        const taskDetailsJson = JSON.stringify({ "task_id": Number(card.id), "authorization": getCookie("token") });
        const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_task_details`, JSON.stringify({ params: taskDetailsJson }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        if (response.data.success === "true" && response.data.task) {
            // const updatedTask = response.data.task;
            await fetchProjectActivity(response.data.task.project_id, response.data.task.id);
            setSelectedCard(response.data.task);
            // console.log(response)
            setTimeout(() => {
                document.querySelector('#taskDetailModalTrigger').click();
            }, 0);
        }
    };

    // useEffect(() => {
    //     // console.log(editCardDetails, "Edit Card Details NEw")
    // }, [editCardDetails])

    const handleAddBoard = () => {
        if (newBoardName.trim()) {
            setBoards([...boards, { title: newBoardName, key: newBoardName.toLowerCase().replace(/\s+/g, '-') }]);
            setNewBoardName(''); // Reset input field
            setShowInput(false); // Hide input field after adding
        }
    };

    const formatProjectId = (id) => {
        return id < 10 ? `0${id}` : id;
    };

    const extractFileName = (filePath) => {
        // Extract the file name
        const fileName = filePath.split('/').pop();

        // Remove everything before the first underscore, including numbers and dots
        return fileName.replace(/^[^_]*_/, '');
    };

    const downloadFile = (filePath) => {
        const fileName = extractFileName(filePath);
        const link = document.createElement('a');
        const filePathNew = process.env.REACT_APP_BASE_API + filePath
        // console.log(filePathNew)
        link.href = filePathNew;
        link.download = fileName;
        link.click();
    };

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
        return color;
    };


    return (
        <>
            <div style={{ height: "100vh" }} className='container-fluid overflow-x-hidden roboto-regular p-0'>
                <div className="row justify-content-center">
                    <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
                        <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
                    </div>
                    <div className={`${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
                        <div className=''>
                            <Header />
                            <div className="mt-3 mb-5 pb-5 p-3">
                                <div className="d-flex justify-content-between">
                                    <h5 className="text-start">#PM{formatProjectId(atob(atob(params.projectId)))} Tasks</h5>
                                    {/* <button type="button" className="btn btn-primary" onClick={() => setShowInput(!showInput)}>
                                Add Board
                            </button> */}
                                    {/* <div className="search-box mt-3 mt-sm-0">
                                        <div className="position-relative">
                                            <input type="text" className="form-control rounded" id="search-team" placeholder="Search..." />
                                            <IoSearch className="uil uil-search search-icon" />
                                        </div>
                                    </div> */}
                                </div>
                                <div>
                                    {showInput && (
                                        <div className="mt-3">
                                            <input
                                                type="text"
                                                value={newBoardName}
                                                onChange={(e) => setNewBoardName(e.target.value)}
                                                placeholder="Enter board name"
                                                className="form-control"
                                            />
                                            <button type="button" className="btn btn-success mt-2" onClick={handleAddBoard}>
                                                Save Board
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div style={{ overflowX: "scroll" }} className=''>
                                    <KanbanBoard
                                        columns={boards}
                                        initialCards={cards}
                                        columnForAddCard="pending"
                                        renderCard={(card, handleDragStart) =>
                                            renderCard(card, userId, handleProjectDetails, handleEditTaskDetails, handleDragStart)
                                        }
                                        renderAddCard={RenderAddCard}
                                        onCardMove={(cardId, newStatus) => handleCardDrop(cardId, newStatus)}
                                    />
                                </div>
                                {selectedCard && (
                                    // <div className="modal fade roboto-regular" id="taskDetailModal" tabIndex="-1" aria-labelledby="taskDetailModalLabel" aria-hidden="true">
                                    //     <div className="modal-dialog modal-xl modal-dialog-centered">
                                    //         <div className="modal-content">
                                    //             <div className="modal-header">
                                    //                 <h1 className="modal-title fs-5" id="taskDetailModalLabel">
                                    //                     #PM{formatProjectId(selectedCard.projectId)} - T{formatProjectId(selectedCard.id)}
                                    //                 </h1>
                                    //                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    //             </div>
                                    //             <div className="modal-body text-start">
                                    //                 <div className='my-2 text-start'>
                                    //                     <h5 className='mb-0 projectdetail-title'>{selectedCard.title}</h5>
                                    //                 </div>
                                    //                 {/* Render other details using selectedCard */}
                                    //             </div>
                                    //             <div className="modal-footer">
                                    //                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>

                                    <div class="modal fade roboto-regular" id="taskDetailModal" tabindex="-1" aria-labelledby="taskDetailModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-xl modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="taskDetailModalLabel">#PM{formatProjectId(selectedCard.project_id)} - T{formatProjectId(selectedCard.id)}</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body text-start">
                                                    <div className='my-2 text-start'>
                                                        <h5 className='mb-0 projectdetail-title'>{selectedCard.task_title}</h5>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-8 mb-lg-0 mb-4'>
                                                            <div>
                                                                <div className='mb-4'>
                                                                    <div className=''>
                                                                        <p className='mb-0 text-muted'>{selectedCard.task_description}</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='mb-4 pb-4 border-bottom'>
                                                                <div className=''>
                                                                    <h5 className='login-label-text-css'>Project Tasks</h5>
                                                                </div>
                                                                <div className='mt-3'>
                                                                    {selectedCard.tasks.map((task, index) => (
                                                                        <div className='d-flex justify-content-between align-items-center projectdetail-tasks-div shadow-sm p-2 px-3 rounded-3 mb-2'>
                                                                            <div>
                                                                                <h5 className="login-label-text-css mb-0 text-muted fw-bold " htmlFor="flexCheckDefault">
                                                                                    {task.task_title}
                                                                                </h5>
                                                                            </div>
                                                                            <div>
                                                                                <span
                                                                                    className={`badge p-2 ${task.task_status === 'in_progress'
                                                                                        ? 'bg-warning-subtle text-warning'
                                                                                        : task.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                                            : 'bg-danger-subtle text-danger'
                                                                                        }`}
                                                                                    style={{ textTransform: 'capitalize' }}
                                                                                >
                                                                                    <small>{task.task_status === 'completed' ? 'Completed' : task.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div> */}
                                                                <div className=''>
                                                                    <div className=''>
                                                                        <h5 className='login-label-text-css'>Task Activities</h5>
                                                                    </div>
                                                                    <div className='mt-3 taskDetail-div-scroll projectdetail-tasks-div rounded-3 p-2'>
                                                                        {taskActivities.length > 0 ? (
                                                                            taskActivities.slice(0, 10).map((activity) => (
                                                                                <div key={activity.activity_id} className='d-flex justify-content-between align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <div className='me-3'>
                                                                                            <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                                <MdOutlineFilePresent />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div dangerouslySetInnerHTML={{ __html: activity.message }} className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                                                {/* {activity.message} */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        )
                                                                            : (
                                                                                <div className='d-flex align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                                    <div className='me-3'>
                                                                                        <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                            <MdOutlineFilePresent />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h5 className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                                            No Activities
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div>
                                                                                        {/* <span
                                                                                    className={`badge p-2 ${file.task_status === 'in_progress'
                                                                                        ? 'bg-warning-subtle text-warning'
                                                                                        : file.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                                            : 'bg-danger-subtle text-danger'
                                                                                        }`}
                                                                                    style={{ textTransform: 'capitalize' }}
                                                                                >
                                                                                    <small>{file.task_status === 'completed' ? 'Completed' : file.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                                </span> */}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div>
                                                                <div className='projectdetail-tasks-div mb-3 shadow-sm p-4 rounded-2'>
                                                                    <div className='border-bottom pb-3'>
                                                                        <h5 className='login-label-text-css mb-0'>
                                                                            Attributes
                                                                        </h5>
                                                                    </div>
                                                                    <div className='pt-3'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div>
                                                                                <h5 className='login-label-text-css mb-0'>
                                                                                    Status
                                                                                </h5>
                                                                            </div>
                                                                            <div>
                                                                                <span
                                                                                    className={`badge px-2 ${selectedCard.task_status === 'in_progress'
                                                                                        ? 'bg-warning-subtle text-warning'
                                                                                        : selectedCard.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                                            : 'bg-danger-subtle text-danger'
                                                                                        }`}
                                                                                    style={{ textTransform: 'capitalize' }}
                                                                                >
                                                                                    <small>{selectedCard.task_status === 'completed' ? 'Completed' : selectedCard.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mt-4'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div>
                                                                                <h5 className='login-label-text-css mb-0'>
                                                                                    Priority
                                                                                </h5>
                                                                            </div>
                                                                            <div>
                                                                                <span
                                                                                    className={`badge px-2 ${selectedCard.task_priority === 'medium'
                                                                                        ? 'bg-warning-subtle text-warning'
                                                                                        : selectedCard.task_priority === 'low' ? 'bg-success-subtle text-success'
                                                                                            : 'bg-danger-subtle text-danger'
                                                                                        }`}
                                                                                    style={{ textTransform: 'capitalize' }}
                                                                                >
                                                                                    <small>{selectedCard.task_priority === 'low' ? 'Low' : selectedCard.task_priority === "medium" ? 'Medium' : 'High'}</small>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='mt-4'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div>
                                                                                <h5 className='login-label-text-css mb-0'>
                                                                                    Label
                                                                                </h5>
                                                                            </div>
                                                                            <div>
                                                                                <h5 className='login-label-text-css mb-0'>
                                                                                    None
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className='mt-4'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div>
                                                                                <h5 className='login-label-text-css mb-0'>
                                                                                    Creator
                                                                                </h5>
                                                                            </div>
                                                                            <div>
                                                                                <h5 className='login-label-text-css mb-0'>
                                                                                    {new Date(selectedCard.created_at).toLocaleDateString()}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='projectdetail-tasks-div mb-4 shadow-sm p-4 rounded-2'>
                                                                    <div className='border-bottom pb-3'>
                                                                        <h5 className='login-label-text-css mb-0'>
                                                                            Team Members
                                                                        </h5>
                                                                    </div>
                                                                    <div className='mt-4'>

                                                                        <div className="position-relative">
                                                                            <div className="d-flex profile-image-stack">

                                                                                <div className="project-member-profile-pic-div-css">
                                                                                    {selectedCard.assignee_photo ? (
                                                                                        <Link to={`/profile/${encodeURIComponent(btoa(btoa(selectedCard.assignee_id)))}`}>
                                                                                            <img className="project-member-profile-pic-css" src={process.env.REACT_APP_BASE_API + selectedCard.assignee_photo} alt={selectedCard.assignee_name} />
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <Link to={`/profile/${encodeURIComponent(btoa(btoa(selectedCard.assignee_id)))}`}>
                                                                                            <div
                                                                                                style={{
                                                                                                    backgroundColor: getColorFromHash(selectedCard.assignee_name || selectedCard.assignee_id.toString()),
                                                                                                    width: '100%',
                                                                                                    height: '100%',
                                                                                                    borderRadius: '50%',
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'center',
                                                                                                    color: 'white',
                                                                                                    fontWeight: 'bold',
                                                                                                    objectFit: 'cover',
                                                                                                    fontSize: '12px',
                                                                                                }}
                                                                                            >
                                                                                                {selectedCard.assignee_name.charAt(0).toUpperCase()}
                                                                                            </div>
                                                                                        </Link>
                                                                                    )}
                                                                                </div>
                                                                                {/* {Object.values(projectDetail.project.users).length > 3 && (
                                                                                <div className="extra-count">+{Object.values(projectDetail.project.users).length - 3}</div>
                                                                            )} */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='projectdetail-tasks-div shadow-sm p-4 rounded-2'>
                                                                    <div className='border-bottom pb-3'>
                                                                        <h5 className='login-label-text-css mb-0'>
                                                                            Task Files
                                                                        </h5>
                                                                    </div>
                                                                    <div className=''>

                                                                        <div style={
                                                                            {
                                                                                overflowY: "scroll",
                                                                                height: "150px"
                                                                            }
                                                                        } className="position-relative">

                                                                            <div className='mt-4 projectdetail-tasks-div rounded-3'>
                                                                                {selectedCard.files.length > 0 ? (
                                                                                    selectedCard.files.map((file, index) => (
                                                                                        <div className='d-flex justify-content-between align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='me-3'>
                                                                                                    <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                                        <MdOutlineFilePresent />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <h5 className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                                                        {extractFileName(file.file_path)}
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div className=''>
                                                                                                    <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                                        <button onClick={() => downloadFile(file.file_path)} className='border-0 bg-transparent p-0'>
                                                                                                            <MdOutlineFileDownload />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                )
                                                                                    : (
                                                                                        <div className='d-flex align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                                            <div className='me-3'>
                                                                                                <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                                    <MdOutlineFilePresent />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <h5 className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                                                    No Attachments
                                                                                                </h5>
                                                                                            </div>
                                                                                            <div>
                                                                                                {/* <span
                                                                    className={`badge p-2 ${file.task_status === 'in_progress'
                                                                        ? 'bg-warning-subtle text-warning'
                                                                        : file.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                            : 'bg-danger-subtle text-danger'
                                                                        }`}
                                                                    style={{ textTransform: 'capitalize' }}
                                                                >
                                                                    <small>{file.task_status === 'completed' ? 'Completed' : file.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                </span> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {editCardDetails && (
                                    <div className="modal fade" id="editTaskDetailModal" tabIndex="-1" aria-labelledby="editTaskDetailModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="editTaskDetailModalLabel">Edit Task</h5>
                                                    <button id='editTaskDetailModalClose' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body text-start">
                                                    <div className="mb-3">
                                                        <label htmlFor="taskName" className="form-label login-label-text-css fw-bold">Task Name</label>
                                                        <p>{editCardDetails.title}</p>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="taskDesc" className="form-label login-label-text-css fw-bold">Task Description</label>
                                                        <p>{editCardDetails.description}</p>
                                                    </div>
                                                    <div className="mt-4 w-100">
                                                        <h5 className="login-label-text-css text-start mb-2">Priority</h5>
                                                        <div className="dropdown">
                                                            <button className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {editingPriority} <IoChevronDownOutline />
                                                            </button>
                                                            <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                                                {['high', 'medium', 'low'].map((level) => (
                                                                    <li key={level}>
                                                                        <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); setEditingPriority(level); }}>
                                                                            {level}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 w-100">
                                                        <h5 className="login-label-text-css text-start mb-2">Status</h5>
                                                        <div className="dropdown">
                                                            <button className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {editingStatus} <IoChevronDownOutline />
                                                            </button>
                                                            <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                                                {['pending', 'in_progress', 'completed'].map((status) => (
                                                                    <li key={status}>
                                                                        <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); setEditingStatus(status); }}>
                                                                            {status.replace('_', ' ')}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 w-100">
                                                        <h5 className="login-label-text-css text-start mb-2">Attachments</h5>
                                                        {(editCardDetails?.files?.length > 0 || editFiles.length > 0) && (
                                                            <div className="mt-3">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr className='text-start'>
                                                                            <th>S.No</th>
                                                                            <th>Filename</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {editCardDetails?.files?.map((file, index) => (
                                                                            <tr key={`existing-${index}`} className='text-start'>
                                                                                <td>{index + 1}</td>
                                                                                <td className='text-start'>{extractFileName(file.file_path)}</td>
                                                                                <td>
                                                                                    <button className="border-0 bg-transparent" onClick={() => handleRemoveAttachment(file.file_id)}>
                                                                                        <LuTrash2 className='text-danger' />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        {editFiles.map((file, index) => (
                                                                            <tr key={`new-${index}`} className='text-start'>
                                                                                <td>{(editCardDetails?.files?.length || 0) + index + 1}</td>
                                                                                <td className='text-start'>{file.name}</td>
                                                                                <td>
                                                                                    <button className="border-0 bg-transparent" onClick={() => handleRemoveEditAttachment(index)}>
                                                                                        <LuTrash2 className='text-danger' />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}
                                                        <div className="mb-3">
                                                            <h5 className="login-label-text-css text-start mb-2">Upload New Files</h5>
                                                            <input type="file" id="editFileUpload" className="form-control" multiple onChange={handleEditFileChange} />
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <h5 className="login-label-text-css text-start mb-2">Edit Team Member</h5>
                                                        <div style={{ maxHeight: '171px', overflowY: 'auto' }} className="align-items-center gap-3">
                                                            {users.map((user) => (
                                                                <div className="form-check d-flex align-items-center mb-3" key={user.id}>
                                                                    <input
                                                                        className="form-check-input me-2"
                                                                        type="radio" // Change to radio for single selection
                                                                        value={user.id}
                                                                        id={`editCheckDefault${user.id}`}
                                                                        checked={selectedEditUserId === user.id}
                                                                        onChange={() => handleEditUserSelect(user.id)}
                                                                    />
                                                                    <label className="form-check-label d-flex login-label-text-css align-items-center" htmlFor={`editCheckDefault${user.id}`}>
                                                                        {user.profile_picture ? (
                                                                            <img style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} className="me-2" src={process.env.REACT_APP_BASE_API + user.profile_picture} alt={user.name} />
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: getColorFromHash(user.name || user.id.toString()),
                                                                                    width: '40px',
                                                                                    height: '40px',
                                                                                    borderRadius: '50%',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    color: 'white',
                                                                                    fontWeight: 'bold',
                                                                                    objectFit: 'cover',
                                                                                    fontSize: '12px',
                                                                                }}
                                                                                className='me-2'
                                                                            >
                                                                                {user.name.charAt(0).toUpperCase()}
                                                                            </div>
                                                                        )}
                                                                        <h5 className="login-label-text-css text-start mb-0">{user.name}</h5>
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                    <button type="button" className="btn btn-primary" onClick={saveTaskChanges}>Save Changes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllTasks;
