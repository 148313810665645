import { useEffect } from 'react';
import { getCookie, base64_decode } from './func';
import logo from './logo.svg';
import './App.css';
import ProjectPage from './Pages/Dashboard/Projects'
import LoginPage from './Pages/LoginPage'
import SignUpPage from './Pages/SignUpPage'
import EmailVerification from './Pages/EmailVerification';
import Alltasks from './Pages/Dashboard/Alltasks';
import Users from './Pages/Dashboard/Users';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import ProfilePage from './Pages/ProfilePage';
import ActivityPage from './Pages/Dashboard/Activity';
import ChangePassword from './Pages/ChangePassword';

function App() {

  return (
    <div className="App">
      <Router>
        {/* <Header></Header> */}
        <Routes>
          <Route exact path='/' element={<LoginPage />}></Route>
          <Route path='/SignUp' element={<SignUpPage />}></Route>
          <Route path='/forgot_password' element={<ForgotPassword />}></Route>
          <Route path='/reset_password/:token' element={<ResetPassword />}></Route>
          <Route path='/verify/:token' element={<EmailVerification />}></Route>
          <Route path='/profile/:id' element={<ProfilePage />}></Route>
          <Route path='/profile' element={<ProfilePage />}></Route>
          <Route path='/activity/:projectId' element={<ActivityPage />}></Route>
          <Route path='/activity/:projectId/:taskId' element={<ActivityPage />}></Route>
          {/* <Route path='/profile/:id' element={<ProfilePage />}></Route> */}
          <Route path='/projects' element={<ProjectPage />}></Route>
          <Route path='/users' element={<Users />}></Route>
          <Route path='/change-password' element={<ChangePassword />}></Route>
          <Route path='/tasks/:projectId/:creatorId' element={<Alltasks />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
