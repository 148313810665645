import React, { useEffect, useState } from 'react'
import './App.css'
import axios from 'axios'
import Alert from '../../Components/Alert'
import { getCookie, setCookie, checkRole } from '../../func';
import { Link, useNavigate, useLocation } from "react-router-dom";

function Index() {

    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        //const checkLoginStatus = getCookie("token");
        //console.log(checkRole());

        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/projects`, { replace: true });
            else
                navigate(`/projects`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const validateUser = (e) => {
        e.preventDefault();

        if (email.trim() !== "" && password.trim() !== "") {
            const json = JSON.stringify({ "email": email.trim(), "password": password.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=login`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });

                    setTimeout(() => {
                        if (typeof location.state?.from !== 'undefined') {
                            setCookie("token", response.data.token, 1);

                            window.localStorage.setItem("isLoggedIn", "true");
                            window.localStorage.setItem("loginSecret", response.data.sceret);
                            // window.dispatchEvent(new Event("storage"));
                            window.localStorage.setItem("dummySync", Date.now());

                            navigate(location.state?.from, { replace: true });
                        }
                        else {
                            setCookie("token", response.data.token, 1);
                            if (checkRole(response.data.token) === "admin")
                                navigate(`/projects`, { replace: true });
                            // else if (checkRole(response.data.token) === "user")
                            //     navigate(`/admin/add_post`, { replace: true });
                            else
                                navigate(`/projects`, { replace: true });

                            window.localStorage.setItem("isLoggedIn", "true");
                            window.localStorage.setItem("loginSecret", response.data.sceret);
                            // window.dispatchEvent(new Event("storage"));
                            window.localStorage.setItem("dummySync", Date.now());
                        }
                    }, 2000);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            if (email.trim() === "" && password.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide email and password to signin", show: true });
            else if (email.trim())
                handleMessage({ type: 'danger', message: "Please provide email to signin", show: true });
            else if (password.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide password to signin", show: true });
        }
    }

    return (
        <>
            <div className='authentication-bg min-vh-100 roboto-medium'>
                <div className="bg-overlay bg-light"></div>
                <div className="container">
                    <div className="d-flex flex-column min-vh-100">
                        <div className="row justify-content-center my-auto">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-5">
                                    <div className="card-body">
                                        <div className="p-3">
                                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                                            <div className="mb-3 text-center mb-md-4">
                                                <Link to="index.html" className="d-block auth-logo">
                                                    <img src="assets/images/logo-dark.png" alt="" height={22} className="auth-logo-dark" />
                                                    <img src="assets/images/logo-light.png" alt="" height={22} className="auth-logo-light" />
                                                </Link>
                                            </div>
                                            <div className="mb-4 text-center">
                                                <h5 className="mb-1">Welcome Back !</h5>
                                                <p className="text-muted">Sign in to continue.</p>
                                            </div>
                                            <form onSubmit={validateUser} className="form-horizontal mt-3">
                                                <div className="mb-3">
                                                    <label className="form-label login-label-text-css d-flex" htmlFor="username">Email</label>
                                                    <input onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" id="username" placeholder="123@example.com" />
                                                </div>
                                                <div className="form-password mb-3 auth-pass-inputgroup">
                                                    <label className="form-label login-label-text-css d-flex" htmlFor="userpassword">Password</label>
                                                    <div className="position-relative">
                                                        <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" id="password-input" placeholder="Enter password" />
                                                        <button type="button" className="btn btn-link position-absolute h-100 end-0 top-0 shadow-none" id="password-addon">
                                                            <i className="mdi mdi-eye-outline font-size-16 text-muted" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-6">
                                                        <div className="form-checkbox d-flex">
                                                            <input type="checkbox" className="form-check-input me-2" id="customControlInline" />
                                                            <label className="form-label login-label-text-css text-muted font-size-13 fw-medium" htmlFor="customControlInline">Remember me</label>
                                                        </div>
                                                    </div>{/* end col */}
                                                    <div className="col-sm-6 text-sm-end">
                                                        <Link to="/forgot_password" className="text-muted login-label-text-css font-size-13 fw-medium text-decoration-none"><i className="mdi mdi-lock" /> Forgot your password?</Link>
                                                    </div>{/* end col */}
                                                </div>{/* end row */}
                                                <div className="row justify-content-center">
                                                    <div className="col-sm-4 text-center">
                                                        <button className="btn btn-primary w-100 rounded-pill fw-bold" type="submit">Log
                                                            In</button>
                                                    </div>
                                                </div>{/* end row */}
                                            </form>{/* end form */}

                                            <div className="mt-3 text-center text-muted">
                                                <p className="mb-0 fw-bold">Don't have an account ? <Link to="/Signup" className="fw-medium text-decoration-none"> Signup
                                                    now </Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-xl-12">
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">©
                                        2024 Probic. Crafted with <i className="mdi mdi-heart text-danger" /> by Themesdesign
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index