import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Header from '../../../Components/Header';
import Sidebar from '../../../Components/Siderbar';
import { IoSearch } from 'react-icons/io5';
import { IoChevronDownOutline } from 'react-icons/io5';
import axios from 'axios';
import { getCookie, base64_decode } from '../../../func';
import { RxDotFilled } from "react-icons/rx";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import avatar1 from '../../../Images/profile/avatar-1.jpg'
import avatar2 from '../../../Images/profile/avatar-3.jpg'
import avatar3 from '../../../Images/profile/avatar-2.jpg'
import { ImAttachment } from "react-icons/im";
import { LuTrash2 } from "react-icons/lu";
import { MdOutlineFilePresent } from "react-icons/md"
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineFileDownload } from "react-icons/md";
import Footer from '../../../Components/Footer';
import Alert from '../../../Components/Alert';

function UserOwnProfileComponent({ Token }) {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const navigate = useNavigate();
    const [profilePicture, setProfilePicture] = useState(null);
    const [userData, setUserData] = useState(null);
    const [initialUserData, setInitialUserData] = useState(null); // Store initial user data
    const [showButtons, setShowButtons] = useState(false); // Initially hide buttons
    const [alert, setAlert] = useState({ type: '', message: '', show: false });

    const inputRef = useRef(null);
    const addressRef = useRef(null);
    const phoneRef = useRef(null);
    const bioRef = useRef(null);

    useEffect(() => {
        fetchUserDetails();
    }, [Token]);

    const fetchUserDetails = async () => {
        const json = JSON.stringify({ "token": Token });
        const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_profile`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        if (response.data.success === "true") {
            const userData = JSON.parse(atob(atob(response.data.result)));
            setUserData(userData);
            setInitialUserData(...userData);
            // console.log(initialUserData)
            addressRef.current.value = userData?.[0].address || '';
            phoneRef.current.value = userData?.[0].phone || '';
            bioRef.current.value = userData?.[0].bio || '';
        }
    };

    const changeHandler = (e) => {
        let files = e.target.files;
        //// console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length === files.length) {
                    // console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setProfilePicture(allFiles);
                    handleInputChange();
                }
            }
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add("drop-zone--over");
    };

    const handleDragLeave = (e) => {
        e.currentTarget.classList.remove("drop-zone--over");
    };

    const handleDrop = (e, inputRef) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            inputRef.current.files = files;
            updateProfilePicture(files[0]);
        }
        e.currentTarget.classList.remove("drop-zone--over");
    };

    // const handleChange = (e) => {
    //     if (e.target.files.length) {
    //         updateProfilePicture(e.target.files[0]);
    //     }
    // };

    const updateProfilePicture = (file) => {
        if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setProfilePicture(reader.result);
                setShowButtons(true); // Show buttons when picture changes
            };
        } else {
            alert("Please upload a valid image file.");
        }
    };

    const updateProfile = () => {
        if (profilePicture?.length > 0 || addressRef.current.value.trim() || phoneRef.current.value.trim() || bioRef.current.value.trim()) {
            const json = JSON.stringify({
                id: btoa(btoa(userData?.[0].id)),
                job_designation: userData?.[0].job_designation,
                address: addressRef.current.value,
                phone: phoneRef.current.value,
                bio: bioRef.current.value,
                profile_picture: profilePicture?.[0], "authorization": getCookie("token")
            });

            // // console.log(json)

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_profile`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((response) => {
                    if (response.data.success === "true") {
                        handleMessage({ type: 'success', message: "Profile updated successfully!", show: true });
                        resetFields();
                        fetchUserDetails();
                    }
                })
                .catch((error) => {
                    handleMessage({ type: 'danger', message: "Something went wrong, try again later!", show: true });
                });
        } else {
            handleMessage({ type: 'danger', message: "Nothing changed. Please update any field before saving", show: true });
        }
    }

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const resetFields = () => {
        // Reset input fields to initial values
        addressRef.current.value = initialUserData.address || '';
        phoneRef.current.value = initialUserData.phone || '';
        bioRef.current.value = initialUserData.bio || '';
        setProfilePicture(null); // Reset profile picture
        setShowButtons(false); // Hide buttons
    }

    // Function to handle input changes
    const handleInputChange = () => {
        setShowButtons(
            addressRef.current.value !== initialUserData.address ||
            phoneRef.current.value !== initialUserData.phone ||
            bioRef.current.value !== initialUserData.bio ||
            profilePicture?.length > 0 // Ensure profilePicture change is detected
        );
    };

    const handleRevertChanges = () => {
        // Reset input fields to initial values
        addressRef.current.value = initialUserData.address || '';
        phoneRef.current.value = initialUserData.phone || '';
        bioRef.current.value = initialUserData.bio || '';
        setProfilePicture(null); // Reset profile picture
        setShowButtons(false); // Hide buttons
    };


    return (
        <>
            <div className='roboto-regular container-fluid overflow-x-hidden roboto-regular p-0'>
                <div className="row justify-content-center">
                    <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
                        <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
                    </div>
                    <div style={{ height: "100vh" }} className={`position-relative ${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
                        <Header />
                        <div className="my-3 p-3 mb-5 pb-5">
                            <div className='mb-5'>
                                <h5 className="text-start">Profile Details</h5>
                                <Alert type={alert.type} message={alert.message} show={alert.show} />
                            </div>
                            <div className='d-flex mb-4 justify-content-center'>
                                <div
                                    className="drop-zone"
                                    onClick={() => inputRef.current.click()}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDragEnd={handleDragLeave}
                                    onDrop={(e) => handleDrop(e, inputRef)}
                                    style={{
                                        width: "150px",
                                        height: "150px",
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        backgroundColor: profilePicture?.[0].base64 || userData?.[0].profile_picture ? "#666" : "#e0e0e0", // Use userData?.profilePicture here
                                        position: "relative",
                                    }}
                                >
                                    <input type="file" accept="image/x-png,image/gif,image/jpeg" className="drop-zone__input" ref={inputRef} onChange={changeHandler} style={{ display: "none" }} />
                                    {(profilePicture?.[0].base64 || userData?.[0].profile_picture) ? ( // Use userData?.profilePicture here 
                                        <img
                                            src={profilePicture?.[0].base64 || process.env.REACT_APP_BASE_API + userData?.[0].profile_picture} // Use userData.profilePicture here
                                            alt="Profile"
                                            style={{ width: "150px", height: "150px", borderRadius: "50%" }}
                                        />
                                    ) : (
                                        <span style={{ color: "#666", textAlign: "center", fontSize: "14px" }}>
                                            Drop image here or click to upload
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='text-start'>
                                <div className='mb-4'>
                                    <h5 className='fw-bold'>Personal Information</h5>
                                </div>
                                <div className='d-md-flex mb-4 justify-content-center gap-4'>
                                    <div className='w-100 mb-4 mb-md-0'>
                                        <div>
                                            <p className='fw-bold mb-3 text-muted'>Username</p>
                                        </div>
                                        <div>
                                            <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                                {userData?.[0].name || 'Loading...'} {/* Display username from userData */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='w-100 mb-4 mb-md-0'>
                                        <div>
                                            <p className='fw-bold mb-3 text-muted'>Email</p>
                                        </div>
                                        <div>
                                            <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                                {userData?.[0].email || 'Loading...'} {/* Display email from userData */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='w-100 mb-4 mb-md-0'>
                                        <div>
                                            <p className='fw-bold mb-3 text-muted'>Job Designation</p>
                                        </div>
                                        <div>
                                            <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                                {userData?.[0].job_designation || 'N/A'} {/* Display jobDesignation from userData */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-sm-flex mb-4 justify-content-center gap-4'>
                                    <div className='w-100 mb-4 mb-sm-0'>
                                        <div>
                                            <p className='fw-bold mb-3 text-muted'>Address</p>
                                        </div>
                                        <div>
                                            <input
                                                placeholder='Enter Address'
                                                className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                                type="address"
                                                ref={addressRef} // Add ref here
                                                onChange={handleInputChange} // Call handleInputChange on change
                                            />
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        <div>
                                            <p className='fw-bold mb-3 text-muted'>Phone</p>
                                        </div>
                                        <div>
                                            <input
                                                placeholder='Enter Phone'
                                                className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                                type="phone"
                                                ref={phoneRef} // Add ref here
                                                onChange={handleInputChange} // Call handleInputChange on change
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <div className='w-100'>
                                        <div>
                                            <p className='fw-bold mb-3 text-muted'>Bio</p>
                                        </div>
                                        <div>
                                            <textarea
                                                style={{ resize: "none" }}
                                                rows={6}
                                                placeholder='Enter Bio'
                                                className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                                ref={bioRef} // Add ref here
                                                onChange={handleInputChange} // Call handleInputChange on change
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Conditionally render buttons */}
                                {showButtons && (
                                    <div className='d-sm-flex mb-4'>
                                        <div className='me-3 mb-4 mb-sm-0'>
                                            <button className='btn rounded-3 btn-primary p-3 px-4' onClick={updateProfile}>
                                                Update Profile
                                            </button>
                                        </div>
                                        <div>
                                            <button className='btn rounded-3 btn-danger p-3 px-4' onClick={handleRevertChanges}>
                                                Revert Changes
                                            </button>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserOwnProfileComponent;

