import React, { useState, useEffect, useRef } from 'react'
import Footer from '../../../Components/Footer'
import Header from '../../../Components/Header'
import Sidebar from '../../../Components/Siderbar'
import { MdVerified } from "react-icons/md";
import axios from 'axios'
import './App.css'
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../func';

// function Users() {
//     const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
//     const [users, setUsers] = useState([]);

//     useEffect(() => {
//         getUsers();
//     }, []);

//     const getColorFromHash = (input) => {
//         const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
//         const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
//         return color;
//     };

//     const getUsers = () => {
//         const json = JSON.stringify({ "offset": 0, "limit": 1000 });
//         axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_users`, JSON.stringify({ params: json }), {
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//         }).then((response) => {
//             if (response.data.success === "true") {
//                 // Assign color once based on user ID or name
//                 const usersWithColor = response.data.users.map(user => ({
//                     ...user,
//                     color: getColorFromHash(user.name || user.id.toString())
//                 }));
//                 setUsers(usersWithColor);
//             }
//         }).catch((error) => {
//             console.error(`Error: ${error}`);
//         });
//     };

//     const addUser = () => {
//         const json = JSON.stringify({ name: "John Doe", email: "email", passowrd: "password", role: "admin" });
//         axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_user`, JSON.stringify({ params: json }), {
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//         }).then((response) => {
//             if (response.data.success === "true") {
//                 // Assign color once based on user ID or name
//                 const usersWithColor = response.data.users.map(user => ({
//                     ...user,
//                     color: getColorFromHash(user.name || user.id.toString())
//                 }));
//                 setUsers(usersWithColor);
//             }
//         }).catch((error) => {
//             console.error(`Error: ${error}`);
//         });
//     };

//     const statusChange = (id, status) => {
//         const json = JSON.stringify({ id, status });
//         axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=change_user_status`, JSON.stringify({ params: json }), {
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//         }).then((response) => {
//             if (response.data.success === "true") {
//                 // Assign color once based on user ID or name
//                 const usersWithColor = response.data.users.map(user => ({
//                     ...user,
//                     color: getColorFromHash(user.name || user.id.toString())
//                 }));
//                 setUsers(usersWithColor);
//             }
//         }).catch((error) => {
//             console.error(`Error: ${error}`);
//         });
//     };

//     return (
//         <>
//             <div className='container-fluid overflow-x-hidden roboto-regular p-0'>
//                 <div className="row justify-content-center">
//                     <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
//                         <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
//                     </div>
//                     <div style={{ height: "100vh" }} className={`${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
//                         <Header />
//                         <div className="mt-3 p-3">
//                             <div>
//                                 <h5 className="text-start">Users</h5>
//                             </div>
//                             <div className='bg-white p-4 rounded-4 shadow-sm mt-4'>
//                                 <div>
//                                     <div className='mb-5'>
//                                         <h5 className='text-start'>
//                                             Create User
//                                         </h5>
//                                     </div>
//                                     <div className='d-flex mb-4 gap-4 text-start justify-content-between'>
//                                         <div className='w-100'>
//                                             <div className='mb-3'>
//                                                 <p className='mb-0 fw-medium text-muted'>
//                                                     User Name <span className='text-danger'>*</span>
//                                                 </p>
//                                             </div>
//                                             <div className='w-100'>
//                                                 <input className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4' placeholder='Enter User Name' type="name" />
//                                             </div>
//                                         </div>
//                                         <div className='w-100'>
//                                             <div className='mb-3'>
//                                                 <p className='mb-0 fw-medium text-muted'>
//                                                     User Email <span className='text-danger'>*</span>
//                                                 </p>
//                                             </div>
//                                             <div className='w-100'>
//                                                 <input className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4' placeholder='Enter User Email' type="email" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className='d-flex mb-4 gap-4 text-start justify-content-between'>
//                                         <div className='w-100'>
//                                             <div className='mb-3'>
//                                                 <p className='mb-0 fw-medium text-muted'>
//                                                     Password <span className='text-danger'>*</span>
//                                                 </p>
//                                             </div>
//                                             <div className='w-100'>
//                                                 <input className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4' placeholder='Enter User Name' type="password" />
//                                             </div>
//                                         </div>
//                                         <div className='w-100'>
//                                             <div className='mb-3'>
//                                                 <p className='mb-0 fw-medium text-muted'>
//                                                     Confirm Password <span className='text-danger'>*</span>
//                                                 </p>
//                                             </div>
//                                             <div className='w-100'>
//                                                 <input className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4' placeholder='Enter User Email' type="password" />
//                                             </div>
//                                         </div>
//                                         <div className='w-100'>
//                                             <div className='mb-3'>
//                                                 <p className='mb-0 fw-medium text-muted'>
//                                                     User Role <span className='text-danger'>*</span>
//                                                 </p>
//                                             </div>
//                                             <div className='w-100'>
//                                                 {/* <input className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4' placeholder='Enter User Email' type="text" /> */}
//                                                 <div className="dropdown">
//                                                     <button className="border text-muted dropdown-after-none text-start rounded-3 bg-transparent dropdown-toggle w-100 p-3 px-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
//                                                         Select Role
//                                                     </button>
//                                                     <ul className="dropdown-menu w-100">
//                                                         <li><a className="dropdown-item" value="admin">Admin</a></li>
//                                                         <li><hr className="dropdown-divider" /></li>
//                                                         <li><a className="dropdown-item" value="user">User</a></li>
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className='text-start'>
//                                         <div>
//                                             <button className='btn btn-primary py-3 px-4'>Create User</button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className='shadow-sm mt-4 mx-3'>
//                                 <div className='row text-muted bg-body-secondary p-2 py-3 fw-bold border-bottom'>
//                                     <div className="col-1">
//                                         <div>
//                                             <div>
//                                                 ID
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-3">
//                                         <div>
//                                             <div>
//                                                 Name
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-2">
//                                         <div>
//                                             <div>
//                                                 Date
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-2">
//                                         <div>
//                                             <div>
//                                                 Verified
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-2">
//                                         <div>
//                                             <div>
//                                                 Status
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-2">
//                                         <div>
//                                             <div>
//                                                 Action
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {users.map((user, index) => (
//                                     <div key={index} className='row text-muted bg-white p-2 py-3 align-items-center fw-bold border-bottom'>
//                                         <div className="col-1">
//                                             <div>
//                                                 <div>
//                                                     {user.id}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-3">
//                                             <div className='text-start'>
//                                                 <div>
//                                                     {user.name}
//                                                 </div>
//                                                 <div>
//                                                     <small className='text-body-secondary'>{user.email}</small>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-2">
//                                             <div>
//                                                 <div>
//                                                     {user.created_at.toString()}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-2">
//                                             <div>
//                                                 <div className='d-flex justify-content-center'>
//                                                     <div className={`p-1 px-2 pe-3 ${user.verified === 1 ? 'bg-success-subtle text-success-emphasis' : 'bg-danger-subtle text-danger-emphasis'} rounded-1`}>
//                                                         <MdVerified className='text-success me-3' />
//                                                         {user.verified === 1 ? 'Verified' : 'Not Verified'}
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-2">
//                                             <div>
//                                                 <div className='d-flex justify-content-center'>
//                                                     <div className={`p-1 px-2 pe-3 ${user.status === 1 ? 'bg-success-subtle text-success-emphasis' : 'bg-danger-subtle text-danger-emphasis'} rounded-1`}>
//                                                         <MdVerified className='text-success me-3' />
//                                                         {user.status === 1 ? 'Active' : 'Not Active'}
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-2">
//                                             <div>
//                                                 {user.status === 1 ? (
//                                                     <div>
//                                                         <div>
//                                                             <button className='btn btn-danger rounded-pill'>De-activate</button>
//                                                         </div>
//                                                     </div>
//                                                 ) : (
//                                                     <div>
//                                                         <div>
//                                                             <button className='btn btn-success rounded-pill'>Activate</button>
//                                                         </div>
//                                                     </div>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                         <Footer></Footer>
//                     </div>
//                 </div>
//             </div >
//         </>
//     )
// }

// function Users() {
//     const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
//     const [users, setUsers] = useState([]);
//     const [newUser, setNewUser] = useState({
//         name: '',
//         email: '',
//         password: '',
//         confirmPassword: '',
//         role: 'Select Role',
//     });

//     useEffect(() => {
//         getUsers();
//     }, []);

//     const getColorFromHash = (input) => {
//         const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
//         const color = `#${((hash & 0xffffff).toString(16)).padStart(6, '0')}`;
//         return color;
//     };

//     const getUsers = () => {
//         const json = JSON.stringify({ offset: 0, limit: 1000 });
//         axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_users`, JSON.stringify({ params: json }), {
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         }).then((response) => {
//             if (response.data.success === 'true') {
//                 const usersWithColor = response.data.users.map((user) => ({
//                     ...user,
//                     color: getColorFromHash(user.name || user.id.toString()),
//                 }));
//                 setUsers(usersWithColor);
//             }
//         }).catch((error) => {
//             console.error(`Error: ${error}`);
//         });
//     };

//     const addUser = () => {
//         const { name, email, password, confirmPassword, role } = newUser;

//         if (password !== confirmPassword) {
//             alert('Passwords do not match!');
//             return;
//         }

//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (!emailRegex.test(email)) {
//             alert('Invalid email format!');
//             return;
//         }

//         if (role === 'Select Role') {
//             alert('Please select a valid role!');
//             return;
//         }

//         const json = JSON.stringify({ name, email, password, role });
//         axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_user`, JSON.stringify({ params: json }), {
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         }).then((response) => {
//             if (response.data.success === 'true') {
//                 // alert('User added successfully!');
//                 getUsers(); // Refresh the users list
//             }
//         }).catch((error) => {
//             console.error(`Error: ${error}`);
//         });
//     };

//     const statusChange = (id, status) => {
//         const json = JSON.stringify({ id, status: status === 1 ? 0 : 1 });
//         axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=activate_deactivate_user`, JSON.stringify({ params: json }), {
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         }).then((response) => {
//             if (response.data.success === 'true') {
//                 // alert('User status updated!');
//                 getUsers(); // Refresh the users list
//             }
//         }).catch((error) => {
//             console.error(`Error: ${error}`);
//         });
//     };

//     return (
//         <div className='container-fluid overflow-x-hidden roboto-regular p-0'>
//             <div className="row justify-content-center">
//                 <div className={`d-none d-xl-block ${sidebarCollapsed ? 'col-xl-1' : 'col-xl-2 '}`}>
//                     <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
//                 </div>
//                 <div style={{ height: '100vh' }} className={`${sidebarCollapsed ? 'col-xl-11' : 'col-xl-10'}`}>
//                     <Header />
//                     <div className="mt-3 p-3">
//                         <div>
//                             <h5 className="text-start">Users</h5>
//                         </div>
//                         <div className='bg-white text-start p-4 rounded-4 shadow-sm mt-4'>
//                             <div>
//                                 <h5 className='text-start'>Create User</h5>
//                             </div>
//                             <div className='d-flex mb-4 gap-4 text-start justify-content-between'>
//                                 <div className='w-100'>
//                                     <input
//                                         className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
//                                         placeholder='Enter User Name'
//                                         type="text"
//                                         value={newUser.name}
//                                         onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
//                                     />
//                                 </div>
//                                 <div className='w-100'>
//                                     <input
//                                         className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
//                                         placeholder='Enter User Email'
//                                         type="email"
//                                         value={newUser.email}
//                                         onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
//                                     />
//                                 </div>
//                             </div>
//                             <div className='d-flex mb-4 gap-4 text-start justify-content-between'>
//                                 <div className='w-100'>
//                                     <input
//                                         className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
//                                         placeholder='Enter Password'
//                                         type="password"
//                                         value={newUser.password}
//                                         onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
//                                     />
//                                 </div>
//                                 <div className='w-100'>
//                                     <input
//                                         className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
//                                         placeholder='Confirm Password'
//                                         type="password"
//                                         value={newUser.confirmPassword}
//                                         onChange={(e) => setNewUser({ ...newUser, confirmPassword: e.target.value })}
//                                     />
//                                 </div>
//                                 <div className='w-100'>
//                                     <select
//                                         className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
//                                         value={newUser.role}
//                                         onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
//                                     >
//                                         <option>Select Role</option>
//                                         <option value="admin">Admin</option>
//                                         <option value="user">User</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             <button className='btn btn-primary py-3 px-4' onClick={addUser}>Create User</button>
//                         </div>
//                         <div className='shadow-sm mt-4 mx-3'>
//                             <div className='row text-muted bg-body-secondary p-2 py-3 fw-bold border-bottom'>
//                                 <div className="col-1">
//                                     <div>
//                                         <div>
//                                             ID
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-3">
//                                     <div>
//                                         <div>
//                                             Name
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-2">
//                                     <div>
//                                         <div>
//                                             Date
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-2">
//                                     <div>
//                                         <div>
//                                             Verified
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-2">
//                                     <div>
//                                         <div>
//                                             Status
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-2">
//                                     <div>
//                                         <div>
//                                             Action
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             {users.map((user, index) => (
//                                 <div key={index} className='row text-muted bg-white p-2 py-3 align-items-center fw-bold border-bottom'>
//                                     <div className="col-1">{user.id}</div>
//                                     <div className="col-3">{user.name}</div>
//                                     <div className="col-2">{user.created_at}</div>
//                                     <div className="col-2">{user.verified === 1 ? 'Verified' : 'Not Verified'}</div>
//                                     <div className="col-2">{user.status === 1 ? 'Active' : 'Not Active'}</div>
//                                     <div className="col-2">
//                                         <button
//                                             className={`btn ${user.status === 1 ? 'btn-danger' : 'btn-success'}`}
//                                             onClick={() => statusChange(user.id, user.status)}
//                                         >
//                                             {user.status === 1 ? 'Deactivate' : 'Activate'}
//                                         </button>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                     <Footer />
//                 </div>
//             </div>
//         </div>
//     );
// }

function Users() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'Select Role',
    });

    const navigate = useNavigate();
    const [profilePicture, setProfilePicture] = useState(null);
    const [userData, setUserData] = useState(null);
    const [initialUserData, setInitialUserData] = useState(null); // Store initial user data
    const [showButtons, setShowButtons] = useState(false); // Initially hide buttons
    const [alert, setAlert] = useState({ type: '', message: '', show: false });
    const [userDetails, setUserDetails] = useState(null);

    const inputRef = useRef(null);
    const addressRef = useRef(null);
    const phoneRef = useRef(null);
    const bioRef = useRef(null);
    const jobDesignationRef = useRef(null);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (userData) {
            if (addressRef.current && phoneRef.current && bioRef.current && jobDesignationRef.current) {
                addressRef.current.value = userData?.[0].address || '';
                phoneRef.current.value = userData?.[0].phone || '';
                bioRef.current.value = userData?.[0].bio || '';
                jobDesignationRef.current.value = userData?.[0].job_designation || '';
            } else {
                console.error("Refs are not yet connected to DOM elements.");
            }
        }
    }, [userData]); // Trigger this effect when userData changes

    useEffect(() => {
        // console.log(initialUserData)
    }, [initialUserData])

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xffffff).toString(16)).padStart(6, '0')}`;
        return color;
    };

    const getUsers = () => {
        const json = JSON.stringify({ offset: 0, limit: 1000, "authorization": getCookie("token") });
        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_users`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === 'true') {
                const usersWithColor = response.data.users.map((user) => ({
                    ...user,
                    color: getColorFromHash(user.name || user.id.toString()),
                }));
                setUsers(usersWithColor);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    };

    const addUser = () => {
        const { name, email, password, confirmPassword, role } = newUser;

        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert('Invalid email format!');
            return;
        }

        if (role === 'Select Role') {
            alert('Please select a valid role!');
            return;
        }

        const json = JSON.stringify({ name, email, password, role , "authorization": getCookie("token")});
        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_user`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === 'true') {
                // // console.log(response.data.user);
                const addedUser = {
                    id: response.data.user[0].id, // Assuming the API returns the ID of the new user
                    name: response.data.user[0].name,
                    email: response.data.user[0].email,
                    created_at: response.data.user[0].created_at, // Mock the creation date
                    verified: response.data.user[0].verified,
                    status: response.data.user[0].status,
                    color: getColorFromHash(name),
                };
                setUsers((prevUsers) => [...prevUsers, addedUser]);
                // alert('User added successfully!');
                setNewUser({ name: '', email: '', password: '', confirmPassword: '', role: 'Select Role' }); // Reset form
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    };

    const statusChange = (id, currentStatus) => {
        const json = JSON.stringify({ id, action: currentStatus === 1 ? "0" : "1", "authorization": getCookie("token") });
        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=activate_deactivate_user`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === 'true') {
                setUsers((prevUsers) =>
                    prevUsers.map((user) =>
                        user.id === id ? { ...user, status: currentStatus === 1 ? 0 : 1 } : user
                    )
                );
                // alert('User status updated!');
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    };

    const fetchUserDetails = async (userId) => {
        const json = JSON.stringify({ "id": btoa(btoa(userId)) });
        const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_profile`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        if (response.data.success === "true") {
            const userNewData = JSON.parse(atob(atob(response.data.result)));
            setUserData(userNewData);
            if (userNewData !== null) {
                setInitialUserData(userNewData);
                // console.log(initialUserData)
            }
        }
    };

    const changeHandler = (e) => {
        let files = e.target.files;
        //// console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length === files.length) {
                    // console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setProfilePicture(allFiles);
                    handleInputChange();
                }
            }
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add("drop-zone--over");
    };

    const handleDragLeave = (e) => {
        e.currentTarget.classList.remove("drop-zone--over");
    };

    const handleDrop = (e, inputRef) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            inputRef.current.files = files;
            updateProfilePicture(files[0]);
        }
        e.currentTarget.classList.remove("drop-zone--over");
    };

    // const handleChange = (e) => {
    //     if (e.target.files.length) {
    //         updateProfilePicture(e.target.files[0]);
    //     }
    // };

    const updateProfilePicture = (file) => {
        if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setProfilePicture(reader.result);
                setShowButtons(true); // Show buttons when picture changes
            };
        } else {
            alert("Please upload a valid image file.");
        }
    };

    const updateProfile = () => {
        if (profilePicture?.length > 0 || addressRef.current.value.trim() || phoneRef.current.value.trim() || bioRef.current.value.trim()) {
            const json = JSON.stringify({
                id: btoa(btoa(userData?.[0].id)),
                job_designation: jobDesignationRef.current.value,
                address: addressRef.current.value,
                phone: phoneRef.current.value,
                bio: bioRef.current.value,
                profile_picture: profilePicture?.[0], "authorization": getCookie("token")
            });

            // // console.log(json)

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_profile`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((response) => {
                    if (response.data.success === "true") {
                        handleMessage({ type: 'success', message: "Profile updated successfully!", show: true });
                        const userEditModalCloseBtn = document.getElementById('userEditModalCloseBtn').click();
                        resetFields();
                        fetchUserDetails();
                    }
                })
                .catch((error) => {
                    handleMessage({ type: 'danger', message: "Something went wrong, try again later!", show: true });
                });
        } else {
            handleMessage({ type: 'danger', message: "Nothing changed. Please update any field before saving", show: true });
        }
    }

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const resetFields = () => {
        // Reset input fields to initial values
        addressRef.current.value = initialUserData[0].address || '';
        phoneRef.current.value = initialUserData[0].phone || '';
        bioRef.current.value = initialUserData[0].bio || '';
        jobDesignationRef.current.value = initialUserData[0].job_designation || '';
        setProfilePicture(null); // Reset profile picture
        setShowButtons(false); // Hide buttons
    }

    // Function to handle input changes
    const handleInputChange = () => {
        // debugger;
        // console.log(initialUserData[0])
        setShowButtons(
            addressRef.current.value !== initialUserData[0].address ||
            phoneRef.current.value !== initialUserData[0].phone ||
            bioRef.current.value !== initialUserData[0].bio ||
            jobDesignationRef.current.value !== initialUserData[0].job_designation ||
            profilePicture?.length > 0 // Ensure profilePicture change is detected
        );
    };

    const handleRevertChanges = () => {
        addressRef.current.value = initialUserData[0].address || '';
        phoneRef.current.value = initialUserData[0].phone || '';
        bioRef.current.value = initialUserData[0].bio || '';
        jobDesignationRef.current.value = initialUserData[0].job_designation || '';
        setProfilePicture(null); // Reset profile picture
        setShowButtons(false); // Hide buttons
    };

    const handleUserEditModal = (userEditDetails) => {
        fetchUserDetails(userEditDetails.id);
        setTimeout(() => {
            const userEditModalBtn = document.getElementById('userEditModalBtn');
            if (userEditModalBtn) userEditModalBtn.click();
        }, 500)
    };

    return (
        <>
            {userData && (
                <div className="modal roboto-regular fade" id="userEditModal" tabIndex={-1} aria-labelledby="userEditModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="userEditModalLabel">Modal title</h1>
                                <button id='userEditModalCloseBtn' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div>

                                        <div className='d-flex mb-4 justify-content-center'>
                                            <div
                                                className="drop-zone"
                                                onClick={() => inputRef.current.click()}
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onDragEnd={handleDragLeave}
                                                onDrop={(e) => handleDrop(e, inputRef)}
                                                style={{
                                                    width: "150px",
                                                    height: "150px",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    cursor: "pointer",
                                                    backgroundColor: profilePicture?.[0].base64 || userData?.[0].profile_picture ? "#666" : "#e0e0e0", // Use userData?.profilePicture here
                                                    position: "relative",
                                                }}
                                            >
                                                <input type="file" accept="image/x-png,image/gif,image/jpeg" className="drop-zone__input" ref={inputRef} onChange={changeHandler} style={{ display: "none" }} />
                                                {(profilePicture?.[0].base64 || userData?.[0].profile_picture) ? ( // Use userData?.profilePicture here 
                                                    <img
                                                        src={profilePicture?.[0].base64 || process.env.REACT_APP_BASE_API + userData?.[0].profile_picture} // Use userData.profilePicture here
                                                        alt="Profile"
                                                        style={{ width: "150px", height: "150px", borderRadius: "50%" }}
                                                    />
                                                ) : (
                                                    <span style={{ color: "#666", textAlign: "center", fontSize: "14px" }}>
                                                        Drop image here or click to upload
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='text-start'>
                                            <div className='mb-4'>
                                                <h5 className='fw-bold'>Personal Information</h5>
                                            </div>
                                            <div className='d-sm-flex mb-4 justify-content-center gap-4'>
                                                <div className='w-100 mb-4 mb-sm-0'>
                                                    <div>
                                                        <p className='fw-bold text-muted'>Username</p>
                                                    </div>
                                                    <div>
                                                        <p className='border mb-0 bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                                            {userData?.[0].name || 'Loading...'} {/* Display username from userData */}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='w-100'>
                                                    <div>
                                                        <p className='fw-bold mb-3 text-muted'>Email</p>
                                                    </div>
                                                    <div>
                                                        <p className='border mb-0 bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                                            {userData?.[0].email || 'Loading...'} {/* Display email from userData */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-lg-flex mb-4 justify-content-center gap-4'>
                                                <div className='w-100 mb-4 mb-lg-0'>
                                                    <div>
                                                        <p className='fw-bold mb-3 text-muted'>Address</p>
                                                    </div>
                                                    <div>
                                                        <input
                                                            placeholder='Enter Address'
                                                            className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                                            type="address"
                                                            ref={addressRef} // Add ref here
                                                            onChange={handleInputChange} // Call handleInputChange on change
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-100 mb-4 mb-lg-0'>
                                                    <div>
                                                        <p className='fw-bold mb-3 text-muted'>Job Designation</p>
                                                    </div>
                                                    <div>
                                                        <input
                                                            placeholder='Enter Address'
                                                            className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                                            type="jobDesignation"
                                                            ref={jobDesignationRef} // Add ref here
                                                            onChange={handleInputChange} // Call handleInputChange on change
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-100'>
                                                    <div>
                                                        <p className='fw-bold mb-3 text-muted'>Phone</p>
                                                    </div>
                                                    <div>
                                                        <input
                                                            placeholder='Enter Phone'
                                                            className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                                            type="phone"
                                                            ref={phoneRef} // Add ref here
                                                            onChange={handleInputChange} // Call handleInputChange on change
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-4'>
                                                <div className='w-100'>
                                                    <div>
                                                        <p className='fw-bold mb-3 text-muted'>Bio</p>
                                                    </div>
                                                    <div>
                                                        <textarea
                                                            style={{ resize: "none" }}
                                                            rows={6}
                                                            placeholder='Enter Bio'
                                                            className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                                            ref={bioRef} // Add ref here
                                                            onChange={handleInputChange} // Call handleInputChange on change
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Conditionally render buttons */}
                                            {showButtons && (
                                                <div className='d-sm-flex'>
                                                    <div className='me-3 mb-4 mb-sm-0'>
                                                        <button className='btn rounded-3 btn-primary p-3 px-4' onClick={updateProfile}>
                                                            Update Profile
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button className='btn rounded-3 btn-danger p-3 px-4' onClick={handleRevertChanges}>
                                                            Revert Changes
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='container-fluid overflow-x-hidden roboto-regular p-0'>
                <div className="row justify-content-center">
                    <div className={`d-none d-xl-block ${sidebarCollapsed ? 'col-xl-1' : 'col-xl-2 '}`}>
                        <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
                    </div>
                    <div style={{ height: '100vh' }} className={`${sidebarCollapsed ? 'col-xl-11' : 'col-xl-10'}`}>
                        <Header />
                        <div className="mt-3 p-3 mt-3 p-3 mb-5 pb-5">
                            <div>
                                <h5 className="text-start">Users</h5>
                            </div>
                            <div className='bg-white text-start p-4 rounded-4 shadow-sm mt-4'>
                                <div>
                                    <h5 className='text-start'>Create User</h5>
                                </div>
                                <div className='d-flex flex-wrap flex-sm-nowrap mb-4 gap-4 text-start justify-content-between'>
                                    <div className='w-100'>
                                        <input
                                            className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
                                            placeholder='Enter User Name'
                                            type="text"
                                            value={newUser.name}
                                            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <input
                                            className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
                                            placeholder='Enter User Email'
                                            type="email"
                                            value={newUser.email}
                                            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-wrap flex-sm-nowrap mb-4 gap-4 text-start justify-content-between'>
                                    <div className='w-100'>
                                        <input
                                            className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
                                            placeholder='Enter Password'
                                            type="password"
                                            value={newUser.password}
                                            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <input
                                            className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
                                            placeholder='Confirm Password'
                                            type="password"
                                            value={newUser.confirmPassword}
                                            onChange={(e) => setNewUser({ ...newUser, confirmPassword: e.target.value })}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <select
                                            className='border rounded-3 bg-transparent focus-visible-none w-100 p-3 px-4'
                                            value={newUser.role}
                                            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                                        >
                                            <option>Select Role</option>
                                            <option value="admin">Admin</option>
                                            <option value="user">User</option>
                                        </select>
                                    </div>
                                </div>
                                <button className='btn btn-primary py-3 px-4' onClick={addUser}>Create User</button>
                            </div>
                            <div className='shadow-sm mt-4 mx-3 mb-5'>
                                <div className='row text-muted bg-body-secondary p-2 py-3 fw-bold border-bottom'>
                                    <div className="col-1">
                                        <div>
                                            <div className='admin-user-font-size'>
                                                ID
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-4 text-start">
                                        <div>
                                            <div className='admin-user-font-size'>
                                                Name
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block col-lg-2">
                                        <div>
                                            <div className='admin-user-font-size'>
                                                Date
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-3">
                                        <div>
                                            <div className='admin-user-font-size'>
                                                Verified
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none d-sm-block col-sm-2">
                                        <div>
                                            <div className='admin-user-font-size'>
                                                Status
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-3 col-3">
                                        <div>
                                            <div className='admin-user-font-size'>
                                                Action
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {users.map((user, index) => (
                                    <div key={index} className='row text-muted bg-white p-2 py-3 align-items-center fw-bold border-bottom'>
                                        <div className="admin-user-font-size col-1">{user.id}</div>
                                        <div className="admin-user-font-size col-lg-3 col-4 text-start">
                                            <button onClick={() => handleUserEditModal(user)} type="button" className="admin-user-font-size user-name-css text-start border-0 bg-transparent">
                                                <div className='fw-bold'>
                                                    {user.name}
                                                </div>
                                                <div className='fw-bold'>
                                                    <small className='text-body-secondary admin-user-email-css'>{user.email}</small>
                                                </div>
                                            </button>
                                            <button id='userEditModalBtn' className='d-none' data-bs-toggle="modal" data-bs-target="#userEditModal">
                                            </button>
                                        </div>
                                        <div className="admin-user-font-size d-none d-lg-block col-lg-2">{user.created_at}</div>
                                        <div className="admin-user-font-size col-sm-2 col-3">{user.verified === 1 ? 'Verified' : 'Not Verified'}</div>
                                        <div className="admin-user-font-size d-none d-sm-block col-sm-2">{user.status === 1 ? 'Active' : 'Not Active'}</div>
                                        <div className="admin-user-font-size col-lg-2 col-sm-3 col-3">
                                            <button
                                                className={`btn admin-user-font-size ${user.status === 1 ? 'btn-danger' : 'btn-success'}`}
                                                onClick={() => statusChange(user.id, user.status)}
                                            >
                                                {user.status === 1 ? 'Deactivate' : 'Activate'}
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users