import React, { useState, useEffect } from 'react';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Sidebar from '../../../Components/Siderbar';
import { useParams } from 'react-router-dom';
import { MdOutlineFilePresent } from 'react-icons/md';
import axios from 'axios';
import { getCookie } from '../../../func';

function Activity() {
    // Access route parameters
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const { projectId, taskId } = useParams();
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        fetchProjectActivity();
    }, [projectId, taskId]);

    const fetchProjectActivity = async () => {
        try {
            const json = taskId ? JSON.stringify({ "project_id": projectId, "task_id": taskId, "authorization": getCookie("token") }) : JSON.stringify({ "project_id": projectId, "authorization": getCookie("token") })
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_API}/api.php?action=get_activities`,
                JSON.stringify({ params: json }),
                {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }
            );

            if (response.data.success === "true") {
                // Assuming each activity has a `timestamp` property
                const sortedActivities = response.data.activities.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                setActivities(sortedActivities);
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', ' at');
    };

    return (
        <>

            <div className='roboto-regular container-fluid overflow-x-hidden roboto-regular p-0'>
                <div className="row justify-content-center">
                    <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
                        <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
                    </div>
                    <div style={{ height: "100vh" }} className={`position-relative ${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
                        <Header />
                        <div className="my-3 mb-5 p-3 pb-5 text-start">
                            <div>
                                <h5>#PM{projectId} {taskId ? `- #T${taskId}` : ''} Activities</h5>
                            </div>
                            <div>
                                <div className=''>
                                    <div className='mt-3 projectdetail-tasks-div rounded-3 p-2'>
                                        {activities.length > 0 ? (
                                            activities.map((activity, index) => (
                                                <div key={index} className='d-flex justify-content-between align-items-center rounded-3 p-2 bg-white mb-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='me-3'>
                                                            <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                <MdOutlineFilePresent />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: activity.message }} className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                {/* {activity.message} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className=''>
                                                            {/* <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                            <button onClick={() => downloadFile(file.file_path)} className='border-0 bg-transparent p-0'>
                                                                                <MdOutlineFileDownload />
                                                                            </button>
                                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                            : (
                                                <div className='d-flex align-items-center rounded-3 p-2 bg-white mb-2'>
                                                    <div className='me-3'>
                                                        <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                            <MdOutlineFilePresent />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                            No Activities
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        {/* <span
                                                                    className={`badge p-2 ${file.task_status === 'in_progress'
                                                                        ? 'bg-warning-subtle text-warning'
                                                                        : file.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                            : 'bg-danger-subtle text-danger'
                                                                        }`}
                                                                    style={{ textTransform: 'capitalize' }}
                                                                >
                                                                    <small>{file.task_status === 'completed' ? 'Completed' : file.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                </span> */}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Activity;
