// import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
// import Header from '../../Components/Header';
// import Sidebar from '../../Components/Siderbar';
// import { IoSearch } from 'react-icons/io5';
// import { IoChevronDownOutline } from 'react-icons/io5';
// import axios from 'axios';
// import { getCookie, base64_decode } from '../../func';
// import { RxDotFilled } from "react-icons/rx";
// import { BiDotsHorizontalRounded } from "react-icons/bi";
// import avatar1 from '../../Images/profile/avatar-1.jpg'
// import avatar2 from '../../Images/profile/avatar-3.jpg'
// import avatar3 from '../../Images/profile/avatar-2.jpg'
// import { ImAttachment } from "react-icons/im";
// import { LuTrash2 } from "react-icons/lu";
// import { MdOutlineFilePresent } from "react-icons/md"
// import { Link, useNavigate } from 'react-router-dom';
// import { MdOutlineFileDownload } from "react-icons/md";
// import Footer from '../../Components/Footer';
// import Alert from '../../Components/Alert';

// function ChangePassword({ Token }) {
//     const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
//     const navigate = useNavigate();
//     const [profilePicture, setProfilePicture] = useState(null);
//     const [userData, setUserData] = useState(null);
//     const [showButtons, setShowButtons] = useState(false); // Initially hide buttons
//     const [alert, setAlert] = useState({ type: '', message: '', show: false });

//     useEffect(() => {
//         const token = getCookie('token');
//         if (token !== undefined && token !== null) {
//             fetchUserDetails();
//         } else {
//             navigate("/", { replace: true });
//         }
//     }, []);

//     const fetchUserDetails = async () => {
//         try {
//             const loginSecret = window.localStorage.getItem('loginSecret');
//             if (loginSecret) {
//                 const decodedData = JSON.parse(atob(atob(loginSecret)));
//                 setUserData(decodedData);
//                 console.log(userData)
//             }
//         } catch (error) {
//             console.error("Error fetching user details:", error);
//         }
//     };

//     const updatePassword = {

//     }

//     return (
//         <>
//             <div className='roboto-regular container-fluid overflow-x-hidden roboto-regular p-0'>
//                 <div className="row justify-content-center">
//                     <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
//                         <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
//                     </div>
//                     <div style={{ height: "100vh" }} className={`position-relative ${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
//                         <Header />
//                         <div className="my-3 p-3 mb-5 pb-5">
//                             <div className='mb-5'>
//                                 <h5 className="text-start">Profile Details</h5>
//                                 <Alert type={alert.type} message={alert.message} show={alert.show} />
//                             </div>
//                             <div className='d-flex mb-4 justify-content-center'>
//                                 <img
//                                     style={{ width: "150px", height: "150px", borderRadius: "50%" }} src={userData?.[0].profile_picture} alt=""
//                                 />
//                             </div>
//                             <div className='text-start'>
//                                 <div className='mb-4'>
//                                     <h5 className='fw-bold'>Personal Information</h5>
//                                 </div>
//                                 <div className='d-md-flex mb-4 justify-content-center gap-4'>
//                                     <div className='w-100 mb-4 mb-md-0'>
//                                         <div>
//                                             <p className='fw-bold mb-3 text-muted'>Username</p>
//                                         </div>
//                                         <div>
//                                             <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
//                                                 {userData?.[0].name || 'Loading...'} {/* Display username from userData */}
//                                             </p>
//                                         </div>
//                                     </div>
//                                     <div className='w-100 mb-4 mb-md-0'>
//                                         <div>
//                                             <p className='fw-bold mb-3 text-muted'>Email</p>
//                                         </div>
//                                         <div>
//                                             <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
//                                                 {userData?.[0].email || 'Loading...'} {/* Display email from userData */}
//                                             </p>
//                                         </div>
//                                     </div>
//                                     <div className='w-100 mb-4 mb-md-0'>
//                                         <div>
//                                             <p className='fw-bold mb-3 text-muted'>Job Designation</p>
//                                         </div>
//                                         <div>
//                                             <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
//                                                 {userData?.[0].job_designation || 'N/A'} {/* Display jobDesignation from userData */}
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className='d-md-flex justify-content-center gap-4'>
//                                     <div className='w-100 mb-4 mb-md-0'>
//                                         <div>
//                                             <p className='fw-bold mb-3 text-muted'>New Password</p>
//                                         </div>
//                                         <div>
//                                             <input type='password' className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3' />
//                                         </div>
//                                     </div>
//                                     <div className='w-100 mb-4 mb-md-0'>
//                                         <div>
//                                             <p className='fw-bold mb-3 text-muted'>Confirm Password</p>
//                                         </div>
//                                         <div>
//                                             <input type='password' className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3' />
//                                         </div>
//                                     </div>
//                                 </div>

//                                 {/* Conditionally render buttons */}
//                                 {showButtons && (
//                                     <div className='d-sm-flex mb-4'>
//                                         <div className='me-3 mb-4 mb-sm-0'>
//                                             <button className='btn rounded-3 btn-primary p-3 px-4'>
//                                                 Change Password
//                                             </button>
//                                         </div>
//                                     </div>
//                                 )}

//                             </div>
//                         </div>
//                         <Footer />
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default ChangePassword;


import React, { useState, useEffect } from 'react';
import './App.css';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Siderbar';
import { getCookie, base64_decode } from '../../func';
import Footer from '../../Components/Footer';
import Alert from '../../Components/Alert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ChangePassword({ Token }) {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alert, setAlert] = useState({ type: '', message: '', show: false });

    useEffect(() => {
        const token = getCookie('token');
        if (token) {
            fetchUserDetails();
        } else {
            navigate("/", { replace: true });
        }
    }, [navigate]);

    const fetchUserDetails = async () => {
        try {
            const loginSecret = window.localStorage.getItem('loginSecret');
            if (loginSecret) {
                const decodedData = JSON.parse(atob(atob(loginSecret)));
                setUserData(decodedData);
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const handlePasswordChange = async () => {
        if (newPassword.trim() === '' || confirmPassword.trim() === '') {
            handleMessage({ type: 'danger', message: 'Both fields are required.', show: true });
            return;
        }
        if (newPassword !== confirmPassword) {
            handleMessage({ type: 'danger', message: 'Passwords do not match.', show: true });
            return;
        }

        try {
            const json = JSON.stringify({ "token": getCookie('token'), "newpassword": newPassword });
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=changepassword`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

            if (response.data.success === "true") {
                handleMessage({ type: 'success', message: response.data.message, show: true });
                setNewPassword('');
                setConfirmPassword('');
            } else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        } catch (error) {
            handleMessage({ type: 'danger', message: 'Error changing password. Please try again.', show: true });
            console.error('Error changing password:', error);
        }
    };

    // const handlePasswordChange = async (projectId, userId) => {
    //     const json = JSON.stringify({ "token": getCookie('token'), "newpassword": newPassword });
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=change_password`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    //         if (response.data.success === "true") {
    //             // console.log('member added in project');
    //             // await getProjectDetails(projectId, userId); // Refresh project details
    //         }
    //     } catch (error) {
    //         console.error(`Error: ${error}`);
    //     }
    // };

    return (
        <div className='roboto-regular container-fluid overflow-x-hidden roboto-regular p-0'>
            <div className="row justify-content-center">
                <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
                    <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
                </div>
                <div style={{ height: "100vh" }} className={`position-relative ${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
                    <Header />
                    <div className="my-3 p-3 mb-5 pb-5">
                        <div className='mb-5'>
                            <h5 className="text-start mb-3">Change Password</h5>
                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                        </div>
                        <div className='mb-4'>
                            <div>
                                <img
                                    src={process.env.REACT_APP_BASE_API + userData?.[0].profile_picture} // Use userData.profilePicture here
                                    alt="Profile"
                                    style={{ width: "150px", height: "150px", borderRadius: "50%" }}
                                />
                            </div>
                        </div>
                        <div className='text-start'>
                            <div className='mb-4'>
                                <h5 className='fw-bold'>Personal Information</h5>
                            </div>
                            <div className='d-md-flex mb-4 justify-content-center gap-4'>
                                <div className='w-100 mb-4 mb-md-0'>
                                    <div>
                                        <p className='fw-bold mb-3 text-muted'>Username</p>
                                    </div>
                                    <div>
                                        <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                            {userData?.[0].name || 'Loading...'} {/* Display username from userData */}
                                        </p>
                                    </div>
                                </div>
                                <div className='w-100 mb-4 mb-md-0'>
                                    <div>
                                        <p className='fw-bold mb-3 text-muted'>Email</p>
                                    </div>
                                    <div>
                                        <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                            {userData?.[0].email || 'Loading...'} {/* Display email from userData */}
                                        </p>
                                    </div>
                                </div>
                                <div className='w-100 mb-4 mb-md-0'>
                                    <div>
                                        <p className='fw-bold mb-3 text-muted'>Job Designation</p>
                                    </div>
                                    <div>
                                        <p className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'>
                                            {userData?.[0].job_designation || 'N/A'} {/* Display jobDesignation from userData */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-md-flex justify-content-center gap-4'>
                                <div className='w-100 mb-4 mb-md-0'>
                                    <div>
                                        <p className='fw-bold mb-3 text-muted'>New Password</p>
                                    </div>
                                    <div>
                                        <input
                                            type='password'
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                            placeholder="Enter new password"
                                        />
                                    </div>
                                </div>
                                <div className='w-100 mb-4 mb-md-0'>
                                    <div>
                                        <p className='fw-bold mb-3 text-muted'>Confirm Password</p>
                                    </div>
                                    <div>
                                        <input
                                            type='password'
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className='border bg-transparent w-100 p-3 px-4 focus-visible-none rounded-3'
                                            placeholder="Confirm new password"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-sm-flex mt-4'>
                                <div className='me-3'>
                                    <button
                                        onClick={handlePasswordChange}
                                        className='btn rounded-3 btn-primary p-3 px-4'
                                    >
                                        Change Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
