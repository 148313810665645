import React, { useState, useEffect } from 'react'
import './App.css'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Alert from '../../Components/Alert'
import { getCookie, checkRole } from '../../func';

function Index() {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ type: '', message: '', show: false });

    useEffect(() => {
        //const checkLoginStatus = getCookie("token");
        //console.log(checkRole());

        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/projects`, { replace: true });
            else
                navigate(`/projects`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const validateUser = (e) => {
        e.preventDefault();

        if (email.trim() !== "" && password.trim() !== "" && username !== "") {
            const json = JSON.stringify({ "name": username.trim(), "email": email.trim(), "password": password.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=register`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });

                    setTimeout(() => {
                        navigate(`/`, { replace: true });
                    }, 3000);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            if (email.trim() === "" && password.trim() === "" && username.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide email and password to register", show: true });
            else if (email.trim())
                handleMessage({ type: 'danger', message: "Please provide email to register", show: true });
            else if (password.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide password to register", show: true });
            else if (username.trim() === "") {
                handleMessage({ type: 'danger', message: "Please provide username", show: true });
            }
        }
    }

    return (
        <>
            <div className='authentication-bg min-vh-100 roboto-medium'>
                <div className="bg-overlay bg-light"></div>
                <div className="container">
                    <div className="d-flex flex-column min-vh-100">
                        <div className="row justify-content-center my-auto">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-5">
                                    <div className="card-body">
                                        <div className="p-3">
                                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                                            <div className="mb-3 text-center mb-md-4">
                                                <a href="index.html" className="d-block auth-logo">
                                                    <img src="assets/images/logo-dark.png" alt="" height={22} className="auth-logo-dark" />
                                                    <img src="assets/images/logo-light.png" alt="" height={22} className="auth-logo-light" />
                                                </a>
                                            </div>
                                            <div className="mb-4 text-center">
                                                <h5 className="mb-1">Register Account</h5>
                                                <p className="text-muted">Get your free account now.</p>
                                            </div>
                                            <form onSubmit={validateUser} className="form-horizontal mt-3">
                                                <div className="mb-3">
                                                    <label className="form-label d-flex login-label-text-css" htmlFor="emailID">Email</label>
                                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" id="emailID" placeholder="Enter e-mail" />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label d-flex login-label-text-css" htmlFor="userName">Username</label>
                                                    <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" className="form-control" id="userName" placeholder="Enter username" />
                                                </div>
                                                <div className="form-password mb-3 auth-pass-inputgroup">
                                                    <label className="form-label d-flex login-label-text-css" htmlFor="userpassword">Password</label>
                                                    <div className="position-relative">
                                                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" id="password-input" placeholder="Enter password" />
                                                        <button type="button" className="btn btn-link position-absolute h-100 end-0 top-0 shadow-none" id="password-addon">
                                                            <i className="mdi mdi-eye-outline font-size-16 text-muted" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <div className="form-checkbox d-flex">
                                                            <input type="checkbox" className="form-check-input me-2" id="customControlInline" />
                                                            <label className="form-label text-muted font-size-13 login-label-text-css" htmlFor="customControlInline">By registering you agree to the Probic
                                                                <span className="text-primary"> Terms of Use</span></label>
                                                        </div>
                                                    </div>{/* end col */}
                                                </div>{/* end row */}
                                                <div className="row justify-content-center">
                                                    <div className="col-sm-4 text-center">
                                                        <button className="btn btn-primary w-100 rounded-pill fw-bold" type="submit">Register</button>
                                                    </div>
                                                </div>{/* end row */}
                                            </form>{/* end */}

                                            <div className="mt-3 text-center text-muted">
                                                <p className="mb-0">Already have an account ? <Link to="/" className="text-decoration-none fw-medium">
                                                    Signin now </Link></p>
                                            </div>
                                        </div>
                                    </div>{/* end card-body */}
                                </div>{/* end card */}
                            </div>{/* end col */}
                        </div>{/* end row */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default Index