import React, { useEffect, useState } from 'react'
import './App.css'
import { getCookie, eraseCookie, checkRole } from '../../func';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaProjectDiagram, FaUser, FaTasks, FaBars, FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';

function Header() {
    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const token = getCookie('token');
        if (token !== undefined && token !== null) {
            fetchUserDetails();
        }
        if (token !== undefined && token !== null && checkRole(token) === "admin") {
            setIsAdmin(true);
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            const loginSecret = window.localStorage.getItem("loginSecret");
            if (loginSecret) {
                const decodedData = JSON.parse(atob(atob(loginSecret)));
                setUserDetails(decodedData);
            } else {
                navigate("/", { replace: true });
            }
        };

        // Listen for changes in localStorage
        window.addEventListener("storage", handleStorageChange);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [navigate]);

    const fetchUserDetails = async () => {
        try {
            const loginSecret = window.localStorage.getItem('loginSecret');
            if (loginSecret) {
                const decodedData = JSON.parse(atob(atob(loginSecret)));
                setUserDetails(decodedData);
                //console.log(userDetails)
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const logOut = () => {
        eraseCookie('token');
        window.localStorage.removeItem('loginSecret');
        navigate('/', { replace: true });
    };

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
        return color;
    };

    return (
        <>
            <div className={`nav-new-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <div className='d-flex p-3 justify-content-between align-items-center'>
                    <h3 className="text-white mb-0">Dashboard</h3>
                    <button className="nav-new-toggle-button" onClick={toggleSidebar}>
                        <FaArrowLeft />
                    </button>
                </div>
                <ul>
                    <li>
                        <NavLink to="/projects">
                            <FaProjectDiagram />
                            <span>Projects</span>
                        </NavLink>
                    </li>
                    {isAdmin && (
                        <li>
                            <NavLink to="/users">
                                <FaUser />
                                <span>Users</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
            {isSidebarOpen && (
                <div
                    className="nav-new-overlay active"
                    onClick={toggleSidebar}
                ></div>
            )}
            <div className=' position-sticky top-0 w-100 footer-css-z-index'>
                <nav className="navbar navbar-expand shadow-sm bg-white">
                    <div className="container-fluid">
                        <Link className='navbar-brand'>
                            <div>
                                <img className='' height="50px" src="https://zuzteccrm.com/img/logo.png" alt="" />
                            </div>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <button
                            className="nav-sidebar-toggler bg-transparent border-0"
                            type="button"
                            onClick={toggleSidebar}
                        >
                            <FaBars />
                        </button>
                        {/* <div></div> */}
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-lg-0">
                            {/* {userDetails && userDetails[0] && userDetails[0].profile_picture !== '' && userDetails[0].profile_picture !== null && userDetails[0].profile_picture !== undefined && ( */}
                                {userDetails && (
                                    <li className="nav-item dropdown text-start me-4">
                                        <a className="nav-link login-label-text-css fw-bold dropdown-toggle dropdown-after-none" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className='d-flex'>
                                                <div className='me-1'>
                                                    {userDetails && userDetails[0] && userDetails[0].profile_picture !== '' && userDetails[0].profile_picture !== null && userDetails[0].profile_picture !== undefined ? (
                                                        <img
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                fontSize: '16px',
                                                            }}
                                                            className="project-member-profile-pic-css"
                                                            src={userDetails[0].profile_picture !== '' && userDetails[0].profile_picture !== null && userDetails[0].profile_picture !== undefined ? process.env.REACT_APP_BASE_API + userDetails[0].profile_picture : null}
                                                            alt={userDetails[0].name || "User"}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                backgroundColor: getColorFromHash(userDetails?.[0]?.name || "Default"),
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                fontSize: '16px',
                                                            }}
                                                        >
                                                            {userDetails?.[0]?.name?.charAt(0).toUpperCase() || "U"}
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <p className='mb-0'>
                                                        {userDetails?.[0]?.name || "User"}
                                                    </p>
                                                    <p className='mb-0 text-start'>
                                                        <small className='text-body-tertiary fw-bold'>
                                                            {userDetails?.[0]?.role === "admin" ? "Administrator" : "User"}
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><Link to={`/profile`} className="dropdown-item">Profile</Link></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><Link to={`/change-password`} className="dropdown-item">Change Password</Link></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><button className="dropdown-item" onClick={logOut}>Logout</button></li>
                                        </ul>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Header;
