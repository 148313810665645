import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getCookie } from '../../func'
import UserOwnProfileComponent from './UserOwnProfile'
import UserPublicProfileComponent from './UserPublicProfile'

function ProfilePage() {
    const [userId, setUserId] = useState(null)
    const [token, setToken] = useState(null)
    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {
        const token = getCookie('token'); // Fetch token from cookies
        if (token && params.id) {
            setUserId(params.id);
            setToken(token);
        } else if (token && !params.id) {
            setToken(token);
            setUserId(null)
        } else if (token) {
            setToken(token);
        }
        else if (params.id) {
            setUserId(params.id);
        } else {
            navigate('/login', { replace: true }); // Redirect to login if neither token nor ID is available
        }
    }, [navigate, params.id]); // Include navigate and params.id in dependency array

    return (
        <>
            {userId
                ? <UserPublicProfileComponent UserId={userId} />
                : <UserOwnProfileComponent Token={token} />}
        </>
    )
}

export default ProfilePage
