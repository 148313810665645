import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaTasks, FaUser, FaProjectDiagram, FaBars, FaArrowLeft } from 'react-icons/fa'; // Import icons
import './App.css';
import ProjectPage from '../../Pages/Dashboard/Projects'
import { checkRole, eraseCookie, getCookie } from '../../func';
import { TbActivity } from "react-icons/tb";
import { Link } from 'react-router-dom';

function Sidebar({ SetSidebarCollapsed }) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = getCookie('token');
        if (token !== undefined && token !== null) {
            fetchUserDetails();
        }
        if (getCookie("token") !== undefined && getCookie("token") !== null && checkRole(getCookie("token")) === "admin") {
            setIsAdmin(true);
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            const loginSecret = window.localStorage.getItem("loginSecret");
            if (loginSecret) {
                const decodedData = JSON.parse(atob(atob(loginSecret)));
                setUserDetails(decodedData);
            } else {
                navigate("/", { replace: true });
            }
        };

        // Listen for changes in localStorage
        window.addEventListener("storage", handleStorageChange);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [navigate]);

    const fetchUserDetails = async () => {
        try {
            const loginSecret = window.localStorage.getItem('loginSecret');
            if (loginSecret) {
                const decodedData = JSON.parse(atob(atob(loginSecret)));
                setUserDetails(decodedData);
                console.log(userDetails)
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
        return color;
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        SetSidebarCollapsed(!isCollapsed);
    };

    const logOut = () => {
        eraseCookie('token');
        window.localStorage.removeItem('loginSecret');
        navigate('/', { replace: true });
    };

    return (
        <>
            <div className='h-100'>
                <div className={`sidebar d-flex flex-column justify-content-between roboto-regular ${isCollapsed ? 'collapsed' : ''}`}>
                    <div>
                        <div className={`d-flex ${isCollapsed ? "justify-content-center" : "justify-content-between"}  align-items-center`}>
                            <div className=''>
                                {!isCollapsed && <h3 className='text-white mb-0 text-start'>Dashboard</h3>}
                            </div>
                            <div>
                                <button className="toggle-button" onClick={toggleSidebar}>
                                    {isCollapsed ? <FaBars /> : <FaArrowLeft />}
                                </button>
                            </div>
                        </div>
                        <nav className='mt-5'>
                            <ul>
                                <li className={`${isCollapsed ? "justify-content-center" : "justify-content-between"}`}>
                                    <NavLink to="/projects">
                                        <FaProjectDiagram />
                                        {!isCollapsed && <span>Projects</span>}
                                    </NavLink>
                                </li>
                                {isAdmin && (
                                    <li className={`${isCollapsed ? "justify-content-center" : "justify-content-between"}`}>
                                        <NavLink to="/users">
                                            <FaUser />
                                            {!isCollapsed && <span>Users</span>}
                                        </NavLink>
                                    </li>
                                )}
                                {/* <li className={`${isCollapsed ? "justify-content-center" : "justify-content-between"}`}>
                            <NavLink to="/tasks" activeClassName="active-link">
                                <FaTasks />
                                {!isCollapsed && <span>All Tasks</span>}
                            </NavLink>
                        </li> */}
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <div>
                        {/* {userDetails && userDetails[0] && userDetails[0].profile_picture !== '' && userDetails[0].profile_picture !== null && userDetails[0].profile_picture !== undefined && ( */}
                            {userDetails && (
                                <li className="nav-item bg-white rounded-3 dropdown text-start">
                                    <a className="nav-link login-label-text-css w-100 fw-bold dropdown-toggle dropdown-after-none" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className='d-flex'>
                                            <div className='me-1'>
                                                {userDetails && userDetails[0] && userDetails[0].profile_picture !== '' && userDetails[0].profile_picture !== null && userDetails[0].profile_picture !== undefined ? (
                                                    <img
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            fontSize: '16px',
                                                        }}
                                                        className="project-member-profile-pic-css"
                                                        src={userDetails[0].profile_picture !== '' && userDetails[0].profile_picture !== null && userDetails[0].profile_picture !== undefined ? process.env.REACT_APP_BASE_API + userDetails[0].profile_picture : null}
                                                        alt={userDetails[0].name || "User"}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            backgroundColor: getColorFromHash(userDetails?.[0]?.name || "Default"),
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        {userDetails?.[0]?.name?.charAt(0).toUpperCase() || "U"}
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <p className='mb-0'>
                                                    {userDetails?.[0]?.name || "User"}
                                                </p>
                                                <p className='mb-0 text-start'>
                                                    <small className='text-body-tertiary fw-bold'>
                                                        {userDetails?.[0]?.role === "admin" ? "Administrator" : "User"}
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu w-100">
                                        <li>
                                            <div className='w-100'>
                                                <Link to={`/profile`} className="dropdown-item text-black login-label-text-css p-0 px-2 fw-bold">Profile</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='w-100'>
                                                <Link to={`/change-password`} className="dropdown-item text-black login-label-text-css p-0 px-2 fw-bold">Change Password</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='w-100'>
                                                <Link type='button' className="dropdown-item login-label-text-css p-0 px-2 fw-bold" onClick={logOut}>Logout</Link>
                                            </div>
                                        </li>
                                        {/* <li><hr className="dropdown-divider" /></li> */}
                                    </ul>
                                </li>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
