import React, { useEffect, useState } from 'react'
import './App.css'
import axios from 'axios'
import Alert from '../../Components/Alert'
import { getCookie, setCookie, checkRole } from '../../func';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';

function EmailVerification() {

    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const params = useParams();
    // const [password, setPassword] = useState('');

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/projects`, { replace: true });
            else
                navigate(`/projects`, { replace: true });
        }
        else {
            if (params.token !== undefined && params.token !== null && params.token.toString().trim() !== "")
                validateToken(params.token);
            else {
                // navigate(`/admin/login`, { replace: true });
                navigate(`/`, { replace: true });
            }
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const validateToken = (token) => {
        if (token.trim() !== "") {
            const json = JSON.stringify({ "token": token.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=validate_token`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    setTimeout(() => {
                        VerifyUser(token.trim());
                    }, 2000);
                }
                else if (response.data.success === "false") {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });

                    setTimeout(() => {
                        // navigate(`/admin/login`, { replace: true });
                        navigate(`/`, { replace: true });
                    }, 3000);
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Request is invalid", show: true });
        }
    }

    const VerifyUser = (token) => {
        if (token.trim() !== "") {
            const json = JSON.stringify({ "verify_token": token.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=verify_user`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "false") {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });

                    setTimeout(() => {
                        // navigate(`/admin/login`, { replace: true });
                        navigate(`/`, { replace: true });
                    }, 3000);
                }
                else if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });

                    setTimeout(() => {
                        // navigate(`/admin/`, { replace: true });
                        navigate(`/`, { replace: true });
                    }, 3000);
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Request is invalid", show: true });
        }
    }

    const validateUser = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation

        if (email.trim() === "") {
            console.warn("Email field is empty");
            // alert("Please provide an email.");
            handleMessage({ type: 'danger', message: 'Please provide an email.', show: true });
        } else if (!emailRegex.test(email.trim())) {
            console.error("Invalid email format");
            handleMessage({ type: 'danger', message: 'Please enter a valid email address.', show: true });
            // alert("Please enter a valid email address.");
        } else {
            console.log("Valid Email Entered:", email); // Proceed if the email format is valid
            handleMessage({ type: 'success', message: 'Email Instructions have been sent to your email.', show: true });
        }
    };

    return (
        <>
            <div className='authentication-bg min-vh-100 roboto-medium'>
                <div className="bg-overlay bg-light"></div>
                <div className="container">
                    <div className="d-flex flex-column min-vh-100">
                        <div className="row justify-content-center my-auto">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-5">
                                    <div className="card-body">
                                        <div className="p-3">
                                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                                            <div className="mb-3 text-center mb-md-4">
                                                <Link to="index.html" className="d-block auth-logo">
                                                    <img src="assets/images/logo-dark.png" alt="" height={22} className="auth-logo-dark" />
                                                    <img src="assets/images/logo-light.png" alt="" height={22} className="auth-logo-light" />
                                                </Link>
                                            </div>
                                            <div className="mb-4 text-center">
                                                <h5 className="mb-1">Verifying your email ...</h5>
                                                <p className="text-muted">We are verifying your email. Please Wait</p>
                                                {/* <Alert type={'success'} message={'Enter your Email and instructions will be sent to you!'} show={true} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-xl-12">
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">©
                                        2024 Probic. Crafted with <i className="mdi mdi-heart text-danger" /> by Themesdesign
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailVerification