import React, { useState, useEffect } from 'react';
import './App.css';
import Header from '../../../Components/Header';
import Sidebar from '../../../Components/Siderbar';
import { IoSearch } from 'react-icons/io5';
import { IoChevronDownOutline } from 'react-icons/io5';
import axios from 'axios';
import { getCookie, base64_decode } from '../../../func';
import { RxDotFilled } from "react-icons/rx";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import avatar1 from '../../../Images/profile/avatar-1.jpg'
import avatar2 from '../../../Images/profile/avatar-3.jpg'
import avatar3 from '../../../Images/profile/avatar-2.jpg'
import { ImAttachment } from "react-icons/im";
// import { CgRemove } from "react-icons/cg";
import { LuTrash2 } from "react-icons/lu";
import { MdOutlineFilePresent } from "react-icons/md"
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineFileDownload } from "react-icons/md";
import Footer from '../../../Components/Footer';
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";

function UserPublicProfileComponent({ UserId }) {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserDetails(UserId);
    }, []);

    const fetchUserDetails = async (userId) => {
        const json = JSON.stringify({ "id": userId });
        const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_profile`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        if (response.data.success === "true") {
            const userNewData = JSON.parse(atob(atob(response.data.result)));
            setUserData(userNewData);
            console.log(userNewData)
            if (userNewData !== null) {
                // setInitialUserData(userNewData);
                // console.log(initialUserData)
            }
        }
    };

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
        return color;
    };

    return (
        <>
            <div className='roboto-regular container-fluid overflow-x-hidden roboto-regular p-0'>
                <div className="row justify-content-center">
                    <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
                        <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
                    </div>
                    <div style={{ height: "100vh" }} className={`position-relative ${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
                        <Header />
                        <div className="mt-3 p-3">
                            <div className='bg-white p-4 rounded-2 shadow-sm'>
                                <div className='row'>
                                    <div className='col-sm-4 border-sm-end'>
                                        <div>
                                            <div className='mb-3 d-flex justify-content-center'>
                                                {/* <img className='user-public-profile-image-css' src={avatar1} alt="" /> */}
                                                {userData && userData[0].profile_picture ? (
                                                    <img className="user-public-profile-image-css" src={process.env.REACT_APP_BASE_API + userData?.[0].profile_picture} alt={userData?.[0].name} />
                                                ) : (
                                                    <div
                                                        style={{
                                                            backgroundColor: getColorFromHash(userData?.[0]?.name || userData?.[0]?.id?.toString() || ''),
                                                            // width: '40px',
                                                            // height: '40px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            objectFit: 'cover',
                                                            fontSize: '50px',
                                                        }}
                                                        className='user-public-profile-image-css'
                                                    >
                                                        {userData?.[0].name.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <div>
                                                    <h5 className='text-primary'>{userData ? userData[0].name : null}</h5>
                                                </div>
                                                <div>
                                                    <h5 className='login-label-text-css'>{userData ? userData[0].job_designation : null}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-8 text-start'>
                                        <div>
                                            <div className='mb-5'>
                                                <div className='mb-3'>
                                                    <h5 className='mb-0 fw-bold text-muted'>Biography</h5>
                                                </div>
                                                <div>
                                                    <p className='mb-0 login-label-text-css'>{userData ? userData[0].bio : null}</p>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className='mb-3'>
                                                    <h5 className='mb-0 login-label-text-css fw-bold text-muted'><MdOutlineEmail className='me-2' /> {userData ? userData[0].email : null}</h5>
                                                </div>
                                                <div>
                                                    <h5 className='mb-0 login-label-text-css text-muted'><MdOutlinePhone className='me-2' /> {userData ? userData[0].phone : null}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer></Footer>
                    </div>
                </div>
            </div >
        </>
    );
}

export default UserPublicProfileComponent;
