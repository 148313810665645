import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Header from '../../../Components/Header';
import Sidebar from '../../../Components/Siderbar';
import { IoSearch } from 'react-icons/io5';
import { IoChevronDownOutline } from 'react-icons/io5';
import axios from 'axios';
import { getCookie, base64_decode, checkRole } from '../../../func';
import { RxDotFilled } from "react-icons/rx";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import avatar1 from '../../../Images/profile/avatar-1.jpg'
import avatar2 from '../../../Images/profile/avatar-3.jpg'
import avatar3 from '../../../Images/profile/avatar-2.jpg'
import { ImAttachment } from "react-icons/im";
// import { CgRemove } from "react-icons/cg";
import { LuTrash2 } from "react-icons/lu";
import { MdOutlineFilePresent } from "react-icons/md"
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineFileDownload } from "react-icons/md";
import { FiGrid } from "react-icons/fi";
import { FaList } from "react-icons/fa6";
import Footer from '../../../Components/Footer';
import { FaChevronDown } from "react-icons/fa6";


function Index() {
    const [progress, setProgress] = useState(0);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Select Category');
    const [userId, setUserId] = useState(0);
    const [projects, setProjects] = useState([]);
    const [productDetails, setProductDetails] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false)
    const [users, setUsers] = useState([])
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [isRequested, setIsRequested] = useState(false);
    const [editingProject, setEditingProject] = useState(null);
    const [priority, setPriority] = useState('Select Priority');
    const [editingPriority, setEditingPriority] = useState('Select Priority');
    const [selectedEditUserIds, setSelectedEditUserIds] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const [editFiles, setEditFiles] = useState([]);
    const [editMembers, setEditMembers] = useState([]);
    const [projectDetail, setProjectDetail] = useState(null);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    // State to store all projects (initial and filtered)
    const [allProjects, setAllProjects] = useState([]);
    const [projectActivities, setProjectActivities] = useState([]);
    const [isGridView, setIsGridView] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortCriteria, setSortCriteria] = useState('');
    const modalRef = useRef(null);
    // State to store filtered projects
    // const [projects, setProjects] = useState([]); 

    const toggleView = (viewType) => {
        // Only toggle if the selected view is different from the current view
        if ((viewType === 'grid' && !isGridView) || (viewType === 'list' && isGridView)) {
            setIsGridView(!isGridView);
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        const token = getCookie('token')
        if (!token || token === undefined || token === null) {
            navigate('/', { replace: true })
        }
    }, [])

    useEffect(() => {

        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            setUserId(base64_decode(getCookie("token")).split('|')[0]);
            // // console.log(base64_decode(getCookie("token")).split('|'))
            setIsAdmin(base64_decode(getCookie("token")).split('|')[3] === "admin" ? true : false)
        }
        if (getCookie("token") !== undefined && getCookie("token") !== null && checkRole(getCookie("token")) === "admin") {
            getProjectCategories();
        }
        getUsers()
    }, [])

    useEffect(() => {
        if (userId !== 0) {
            setTimeout(() => {
                getProjects()
            }, 1000 * 60)
            getProjects()
        }
    }, [userId])

    // const handleSort = (criteria) => {
    //     setSortCriteria(criteria);
    //     let filteredProjects = [...allProjects];

    //     switch (criteria) {
    //         case 'status-pending':
    //             filteredProjects = filteredProjects.filter(project => project.status === 'pending');
    //             break;
    //         case 'status-in-progress':
    //             filteredProjects = filteredProjects.filter(project => project.status === 'in_progress');
    //             break;
    //         case 'status-completed':
    //             filteredProjects = filteredProjects.filter(project => project.status === 'completed');
    //             break;
    //         case 'priority-low':
    //             filteredProjects = filteredProjects.filter(project => project.priority === 'low');
    //             break;
    //         case 'priority-medium':
    //             filteredProjects = filteredProjects.filter(project => project.priority === 'medium');
    //             break;
    //         case 'priority-high':
    //             filteredProjects = filteredProjects.filter(project => project.priority === 'high');
    //             break;
    //         default:
    //             filteredProjects = allProjects; // Reset to all projects if no criteria is selected
    //             break;
    //     }

    //     setProjects(filteredProjects);
    // };

    const handleSortAndFilter = (criteria) => {
        setSortCriteria(criteria);
        applyFilters(searchTerm, criteria);
    };

    // Function to apply both search and filter criteria
    const applyFilters = (term, criteria) => {
        let filteredProjects = [...allProjects];

        // Apply search filter
        if (term.length >= 3) {
            filteredProjects = filteredProjects.filter(project =>
                project.name.toLowerCase().includes(term)
            );
        }

        // Apply sorting/filter criteria
        switch (criteria) {
            case 'status-pending':
                filteredProjects = filteredProjects.filter(project => project.status === 'pending');
                break;
            case 'status-in-progress':
                filteredProjects = filteredProjects.filter(project => project.status === 'in_progress');
                break;
            case 'status-completed':
                filteredProjects = filteredProjects.filter(project => project.status === 'completed');
                break;
            case 'priority-low':
                filteredProjects = filteredProjects.filter(project => project.priority === 'low');
                break;
            case 'priority-medium':
                filteredProjects = filteredProjects.filter(project => project.priority === 'medium');
                break;
            case 'priority-high':
                filteredProjects = filteredProjects.filter(project => project.priority === 'high');
                break;
            default:
                break;
        }

        setProjects(filteredProjects);
    }

    // Update the function to handle user selection
    const handleUserSelect = (userId) => {
        setSelectedUserIds((prevSelectedUserIds) => {
            if (prevSelectedUserIds.includes(userId)) {
                // If user is already selected, remove them from the array
                return prevSelectedUserIds.filter(id => id !== userId);
            } else {
                // Otherwise, add the user to the array
                return [...prevSelectedUserIds, userId];
            }
        });
    };

    const handleEditUserSelect = (userId) => {
        setSelectedEditUserIds((prevSelectedUserIds) => {
            if (prevSelectedUserIds.includes(userId)) {
                return prevSelectedUserIds.filter(id => id !== userId);
            } else {
                return [...prevSelectedUserIds, userId];
            }
        });
    };

    const handleProgressChange = (e) => {
        setProgress(e.target.value);
    };

    // Handle file selection
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        // Filter out files that already exist in the selectedFiles list
        const newFiles = files.filter((file) => {
            return !selectedFiles.some((selectedFile) => selectedFile.name === file.name && selectedFile.size === file.size);
        });

        // Update state with new unique files
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    // Handle file removal
    const handleRemoveFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };


    const getProjectCategories = () => {
        const json = JSON.stringify({ "offset": 0, "limit": 1000, "authorization": getCookie("token") });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_project_categories`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true" && response.data.categories) {
                setCategories(response.data.categories); // Assuming the categories are in `data` key.
                // // console.log(response.data.categories);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    };

    const getProjects = () => {
        const json = JSON.stringify({ id: userId, "offset": 0, "limit": 1000, "authorization": getCookie("token") });
        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_project`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                if (response.data.success === "true") {
                    // console.log(response.data);
                    // Sort the projects by 'created_at' in descending order 
                    const sortedProjects = response.data.projects.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    // Update both allProjects and projects with the fetched data
                    setAllProjects(sortedProjects);
                    setProjects(sortedProjects);
                }
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
            });
    };

    // Function to handle search input change
    const handleSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        applyFilters(term, sortCriteria);
    };

    // const getUsers = () => {
    //     const json = JSON.stringify({ "offset": 0, "limit": 1000 });

    //     axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_users`, JSON.stringify({ params: json }), {
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         }
    //     }).then((response) => {
    //         if (response.data.success === "true") {
    //             // console.log(response.data); // Assuming the categories are in `data` key.
    //             // // console.log(response.data.categories);
    //             setUsers(response.data.users);
    //         }
    //     }).catch((error) => {
    //         console.error(`Error: ${error}`);
    //     });
    // };

    const getUsers = () => {
        const json = JSON.stringify({ "offset": 0, "limit": 1000, "authorization": getCookie("token") });
        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_users`, JSON.stringify({ params: json }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            if (response.data.success === "true") {
                // Assign color once based on user ID or name
                const usersWithColor = response.data.users.map(user => ({
                    ...user,
                    color: getColorFromHash(user.name || user.id.toString())
                }));
                setUsers(usersWithColor);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    };


    const handleCategorySelect = (category) => {
        setSelectedCategory(category.name);
    };

    // Function to add a new project, including selected user IDs
    // const addProject = async (projectName, projectDesc, dueDate, categoryId, progress, priority) => {
    //     setIsRequested(true);
    //     const userIdsToSend = selectedUserIds.length > 0 ? selectedUserIds : [userId];
    //     const json = JSON.stringify({
    //         name: projectName,
    //         description: projectDesc,
    //         due_date: dueDate,
    //         category_id: categoryId,
    //         progress: progress,
    //         priority: priority,
    //         created_by: userId,
    //         status: 'pending',
    //         created_at: new Date().toISOString(),
    //         members: userIdsToSend
    //     });

    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_project`, JSON.stringify({ params: json }), {
    //             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //         });

    //         if (response.data.success === "true") {
    //             setProjects([...projects, response.data.new_project]);
    //             resetProjectModal(); // Reset after adding project
    //         }
    //     } catch (error) {
    //         console.error(`Error: ${error}`);
    //     } finally {
    //         setIsRequested(false);
    //     }
    // };

    const addProject = async (projectName, projectDesc, dueDate, categoryId, progress, priority) => {
        setIsRequested(true);
        const userIdsToSend = selectedUserIds.length > 0 ? selectedUserIds : [userId];
        const json = JSON.stringify({
            name: projectName,
            description: projectDesc,
            due_date: dueDate,
            category_id: categoryId,
            progress: progress,
            priority: priority,
            created_by: userId,
            status: 'pending',
            created_at: new Date().toISOString(),
            members: userIdsToSend, "authorization": getCookie("token")
        });
        const formData = new FormData();
        formData.append("params", JSON.stringify(json));

        // Append multiple files
        if (selectedFiles && selectedFiles.length > 0) {
            selectedFiles.forEach((file, index) => {
                formData.append(`files[]`, file); // `file_0`, `file_1`, etc.
            });
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_project`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.success === "true") {
                // Get the new project ID
                const newProjectId = response.data.new_project.id;

                // console.log(newProjectId);

                // Fetch the details of the newly created project
                const projectDetails = await getProjectDetails(newProjectId, userId);

                // Update the project list with the detailed new project
                setProjects([projectDetails.project, ...projects]);

                resetProjectModal();
                resetAllFields();
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        } finally {
            setIsRequested(false);
        }
    };


    useEffect(() => {
        const modalElement = modalRef.current;

        const handleModalShow = () => {
            // Actions to perform when the modal is shown
        };

        const handleModalHide = () => {
            resetProjectModal();
            resetAllFields();
        };

        if (modalElement) {
            modalElement.addEventListener('show.bs.modal', handleModalShow);
            modalElement.addEventListener('hide.bs.modal', handleModalHide);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('show.bs.modal', handleModalShow);
                modalElement.removeEventListener('hide.bs.modal', handleModalHide);
            }
        };
    }, []);

    const resetProjectModal = () => {
        document.querySelector('#addModalCloseBtn').click();
        setPriority('Select Priority');
        setProgress(0);
        setSelectedCategory('Select Category');
        setSelectedUserIds([]);
        const inputs = document.querySelectorAll('.form-check-input');
        inputs.forEach((input) => {
            input.checked = false;
        });
    };

    const resetAllFields = () => {
        setPriority('Select Priority');
        setProgress(0);
        setSelectedCategory('Select Category');
        setSelectedUserIds([]);
        setSelectedFiles([]);
        document.querySelector('#projectName').value = '';
        document.querySelector('#projectDesc').value = '';
        document.querySelector('#dueDate').value = '';
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const getColorFromHash = (input) => {
        const hash = Array.from(input).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
        return color;
    };

    // Ensure file states are reset when a new project is selected for editing
    const handleEditProject = (project) => {
        setEditingProject(project);
        setEditingPriority(project.priority || 'Select Priority');
        setSelectedCategory(categories.find(cat => cat.id === project.category_id)?.name || 'Select Category');
        setProgress(project.progress);

        // Reset file states for the selected project
        setEditFiles([]); // Clear newly selected files
        setRemovedFiles([]); // Clear removed files

        const preSelectedUserIds = Object.values(project.users).map(user => user.user_id);
        setSelectedEditUserIds(preSelectedUserIds);
        setEditMembers(preSelectedUserIds); // Initialize editMembers with current project members
        setTimeout(() => {
            document.querySelector('#editDueDate').value = project.due_date;
            document.querySelector('#editModalTrigger').click();
        }, 0);
    };

    const resetEditModal = () => {
        document.querySelector('#editModalCloseBtn').click();
        setEditingPriority('Select Priority');
        setProgress(0);
    };

    const handleStatusChange = (status) => {
        setEditingProject((prevProject) => ({
            ...prevProject,
            status: status
        }));
    };

    const getProjectDetails = async (projectId, creatorId) => {
        try {
            // console.log(projectId);
            const json = JSON.stringify({ "id": creatorId, "project_id": projectId, "authorization": getCookie("token") });
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_project_details`, JSON.stringify({ params: json }), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });

            if (response.data.success === "true") {
                // Handle the project details including tasks
                // console.log(response.data);

                // Update the specific project in the projects array
                setProjects(prevProjects => prevProjects.map(project =>
                    project.id === projectId ? { ...project, ...response.data.project } : project
                ));
                return response.data;
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const getCreatorId = (creatorName, users) => {
        const user = users.find(user => user.name === creatorName);
        // console.log(user)
        return user ? user.id : null; // Return the user ID if found, otherwise return null
    };

    // Function to handle file upload in the edit modal
    // const handleEditFileChange = async (e) => {
    //     const files = Array.from(e.target.files);
    //     // console.log(editingProject.id)
    //     await addFilesToProject(editingProject.id, files);
    //     // Refresh the project details to reflect the new files
    //     const creatorId = await getCreatorId(editingProject.created_by, users);
    //     const updatedProject = await getProjectDetails(editingProject.id, creatorId);
    //     setEditingProject(updatedProject);
    // };

    // // Function to handle file removal in the edit modal
    // const handleRemoveAttachment = async (fileId) => {
    //     // await removeFileFromProject(editingProject.id, fileId);
    //     // Refresh the project details to reflect the removed file
    //     // console.log(editingProject)
    //     const creatorId = await getCreatorId(editingProject.created_by, users);
    //     const updatedProject = await getProjectDetails(editingProject.id, creatorId);
    //     setEditingProject(updatedProject);
    //     // console.log(editingProject, "new project")
    // };

    const handleEditFileChange = (e) => {
        const files = Array.from(e.target.files);
        const newFiles = files.filter((file) => {
            return !editFiles.some((editFile) => editFile.name === file.name && editFile.size === file.size);
        });
        setEditFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    // Updated handleRemoveEditAttachment to remove files from the editFiles state
    const handleRemoveEditAttachment = (fileId) => {
        setEditFiles((prevFiles) => prevFiles.filter((_, i) => i !== fileId));
    }

    const handleRemoveAttachment = (fileId) => {
        // Assuming you have a state to track removed files
        setRemovedFiles((prevRemovedFiles) => [...prevRemovedFiles, fileId]);

        // Optionally, update the editingProject state to reflect the removal
        setEditingProject((prevProject) => ({
            ...prevProject,
            files: prevProject.files.filter(file => file.file_id !== fileId)
        }));
    };

    const resetFileStates = () => {
        setEditFiles([]); // Clear newly selected files
        setRemovedFiles([]); // Clear removed files
    };

    const addFilesToProject = async (projectId, files) => {
        const json = JSON.stringify({ "project_id": projectId, "user_id": userId, "authorization": getCookie("token") });
        const formData = new FormData();
        formData.append("params", JSON.stringify(json));
        if (files && files.length > 0) {
            files.forEach((file, index) => {
                formData.append(`files[]`, file);
            });
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_files_to_project`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.success === "true") {
                // console.log('Files added to project successfully');
                await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    // Function to remove files from the project and refresh project details
    const removeFileFromProject = async (projectId, fileId) => {
        const json = JSON.stringify({ "project_id": projectId, "file_id": fileId, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=remove_file_from_project`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                // console.log('File removed from project successfully');
                await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    // Function to save changes to the project
    // const saveProjectChanges = async () => {
    //     const updatedProject = {
    //         ...editingProject,
    //         due_date: document.querySelector('#editDueDate').value,
    //         category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
    //         progress: progress,
    //         priority: editingPriority,
    //         users: users.filter(user => selectedEditUserIds.includes(user.user_id))
    //     };

    //     addFilesToProject(editingProject.id, editFiles);
    //     removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));
    //     // console.log('Updated Project:', updatedProject);

    //     resetFileStates();
    //     resetEditModal();
    //     const creatorId = await getCreatorId(editingProject.created_by, users);

    //     // Delay fetching project details by 1 second
    //     setTimeout(() => {
    //         getProjectDetails(editingProject.id, creatorId);
    //     }, 1000);
    // };

    const addProjectMembers = async (projectId, userId) => {
        const json = JSON.stringify({ "project_id": projectId, "user_id": userId, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=add_project_member`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                // console.log('member added in project');
                // await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const removeProjectMembers = async (projectId, userId) => {
        const json = JSON.stringify({ "project_id": projectId, "user_id": userId, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=remove_project_member`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                // console.log('member removed in project');
                // await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const updateProjectStatus = async (projectId, status) => {
        const json = JSON.stringify({ "project_id": projectId, "status": status, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_project_status`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                const projectActivityJson = JSON.stringify({ "project_id": projectId, "authorization": getCookie("token") });
                const fetchActivity = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_activities`, JSON.stringify({ params: projectActivityJson }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
                if (fetchActivity.data.success === "true") {
                    // setActivities(fetchActivity.data.activities);
                    console.log(fetchActivity.data);
                }
                // console.log('member removed in project');
                // await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const updateProjectDueDate = async (projectId, dueDate) => {
        const json = JSON.stringify({ "project_id": projectId, "due_date": dueDate, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_project_deadline`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                const projectActivityJson = JSON.stringify({ "project_id": projectId });
                const fetchActivity = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_activities`, JSON.stringify({ params: projectActivityJson }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
                if (fetchActivity.data.success === "true") {
                    // setActivities(fetchActivity.data.activities);
                    console.log(fetchActivity.data);
                }
                // console.log('member removed in project');
                // await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const updateProjectCategory = async (projectId, categorId) => {
        const json = JSON.stringify({ "project_id": projectId, "category_id": categorId, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_project_category`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                // console.log('member removed in project');
                // await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const updateProjectProgress = async (projectId, progress) => {
        const json = JSON.stringify({ "project_id": projectId, "progress": progress, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_project_progress`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                // console.log('member removed in project');
                // await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const updateProjectPriority = async (projectId, priority) => {
        const json = JSON.stringify({ "project_id": projectId, "priority": priority, "authorization": getCookie("token") });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=update_project_priority`, JSON.stringify({ params: json }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data.success === "true") {
                // console.log('member removed in project');
                // await getProjectDetails(projectId, userId); // Refresh project details
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    // const saveProjectChanges = () => {
    //     const updatedProject = {
    //         ...editingProject,
    //         due_date: document.querySelector('#editDueDate').value,
    //         category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
    //         progress: progress,
    //         priority: editingPriority,
    //         users: users.filter(user => selectedEditUserIds.includes(user.user_id))
    //     };

    //     addFilesToProject(editingProject.id, editFiles);
    //     removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));
    //     // console.log('Updated Project:', updatedProject);

    //     resetFileStates();
    //     resetEditModal();

    //     // Reset the file input field
    //     document.querySelector('#editFileUpload').value = '';

    //     // Delay fetching project details by 1 second
    //     setTimeout(() => {
    //         getProjectDetails(editingProject.id, userId);
    //     }, 1000);
    // };

    // const saveProjectChanges = () => {
    //     const updatedProject = {
    //         ...editingProject,
    //         due_date: document.querySelector('#editDueDate').value,
    //         category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
    //         progress: progress,
    //         priority: editingPriority,
    //         users: users.filter(user => editMembers.includes(user.user_id)) // Use editMembers for updated users
    //     };

    //     // Correct the logic for adding and removing members
    //     const membersToAdd = selectedEditUserIds.filter(id => !editMembers.includes(id));
    //     const membersToRemove = editMembers.filter(id => !selectedEditUserIds.includes(id));

    //     // console.log('membersToAdd', membersToAdd, 'membersToRemove', membersToRemove);

    //     // Add new members
    //     membersToAdd.forEach(userNewId => addProjectMembers(editingProject.id, userNewId));
    //     // Remove existing members
    //     membersToRemove.forEach(userNewId => removeProjectMembers(editingProject.id, userNewId));

    //     addFilesToProject(editingProject.id, editFiles);
    //     removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));
    //     // console.log('Updated Project:', updatedProject);
    //     resetFileStates();
    //     resetEditModal();
    //     document.querySelector('#editFileUpload').value = '';
    //     setTimeout(() => {
    //         getProjectDetails(editingProject.id, userId);
    //     }, 1000);
    // };

    // const saveProjectChanges = async () => {
    //     const updatedProject = {
    //         ...editingProject,
    //         due_date: document.querySelector('#editDueDate').value,
    //         category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
    //         progress: progress,
    //         priority: editingPriority,
    //         users: users.filter(user => editMembers.includes(user.user_id))
    //     };

    //     const membersToAdd = selectedEditUserIds.filter(id => !editMembers.includes(id));
    //     const membersToRemove = editMembers.filter(id => !selectedEditUserIds.includes(id));

    //     // console.log('membersToAdd', membersToAdd, 'membersToRemove', membersToRemove);

    //     membersToAdd.forEach(userNewId => addProjectMembers(editingProject.id, userNewId));
    //     membersToRemove.forEach(userNewId => removeProjectMembers(editingProject.id, userNewId));

    //     addFilesToProject(editingProject.id, editFiles);
    //     removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));

    //     // Update the project status
    //     updateProjectStatus(editingProject.id, editingProject.status);

    //     // console.log('Updated Project:', updatedProject);
    //     resetFileStates();
    //     resetEditModal();
    //     document.querySelector('#editFileUpload').value = '';
    //     const creatorId = await getCreatorId(editingProject.created_by, users);
    //     setTimeout(() => {
    //         getProjectDetails(editingProject.id, creatorId);
    //     }, 1000);
    // };

    // const saveProjectChanges = async () => {
    //     const updatedProject = {
    //         ...editingProject,
    //         due_date: document.querySelector('#editDueDate').value,
    //         category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
    //         progress: progress,
    //         priority: editingPriority,
    //         users: users.filter(user => editMembers.includes(user.user_id))
    //     };

    //     const membersToAdd = selectedEditUserIds.filter(id => !editMembers.includes(id));
    //     const membersToRemove = editMembers.filter(id => !selectedEditUserIds.includes(id));

    //     // console.log('membersToAdd', membersToAdd, 'membersToRemove', membersToRemove);

    //     membersToAdd.forEach(userNewId => addProjectMembers(editingProject.id, userNewId));
    //     membersToRemove.forEach(userNewId => removeProjectMembers(editingProject.id, userNewId));

    //     addFilesToProject(editingProject.id, editFiles);
    //     removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));

    //     // Update project status, due date, category, and progress
    //     updateProjectStatus(editingProject.id, editingProject.status);
    //     updateProjectDueDate(editingProject.id, updatedProject.due_date);
    //     updateProjectCategory(editingProject.id, updatedProject.category_id);
    //     updateProjectProgress(editingProject.id, updatedProject.progress);
    //     updateProjectPriority(editingProject.id, updatedProject.priority);

    //     // console.log('Updated Project:', updatedProject);
    //     resetFileStates();
    //     resetEditModal();
    //     document.querySelector('#editFileUpload').value = '';

    //     const creatorId = await getCreatorId(editingProject.created_by, users);
    //     setTimeout(() => {
    //         getProjectDetails(editingProject.id, creatorId);
    //     }, 1000);
    // };

    const saveProjectChanges = async () => {
        const updatedProject = {
            ...editingProject,
            due_date: document.querySelector('#editDueDate').value,
            category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
            progress: progress,
            priority: editingPriority,
            users: users.filter(user => editMembers.includes(user.user_id))
        };

        const membersToAdd = selectedEditUserIds.filter(id => !editMembers.includes(id));
        const membersToRemove = editMembers.filter(id => !selectedEditUserIds.includes(id));

        // console.log('membersToAdd', membersToAdd, 'membersToRemove', membersToRemove);

        // Check if the due date has changed before updating
        if (editingProject.due_date !== updatedProject.due_date) {
            updateProjectDueDate(editingProject.id, updatedProject.due_date);
        }

        // Check if the category has changed before updating
        if (editingProject.category_id !== updatedProject.category_id) {
            updateProjectCategory(editingProject.id, updatedProject.category_id);
        }

        // Check if the progress has changed before updating
        if (editingProject.progress !== updatedProject.progress) {
            updateProjectProgress(editingProject.id, updatedProject.progress);
        }

        // Check if the progress has changed before updating
        if (editingProject.status) {
            updateProjectStatus(editingProject.id, editingProject.status);
        }

        // Check if the priority has changed before updating
        if (editingProject.priority !== updatedProject.priority) {
            updateProjectPriority(editingProject.id, updatedProject.priority);
        }

        // Add new members if there are any
        membersToAdd.forEach(userNewId => addProjectMembers(editingProject.id, userNewId));

        // Remove members if there are any
        membersToRemove.forEach(userNewId => removeProjectMembers(editingProject.id, userNewId));

        // Add files if there are any new files
        if (editFiles.length > 0) {
            addFilesToProject(editingProject.id, editFiles);
        }

        // Remove files if there are any files to remove
        if (removedFiles.length > 0) {
            removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));
        }

        // console.log('Updated Project:', updatedProject);

        resetFileStates();
        resetEditModal();
        document.querySelector('#editFileUpload').value = '';

        const creatorId = await getCreatorId(editingProject.created_by, users);
        setTimeout(() => {
            getProjectDetails(editingProject.id, creatorId);
        }, 1000);
    };

    const handleProjectDetails = async (project) => {
        try {
            // console.log("Project:", project); // Check the project input
            const creatorId = await getCreatorId(project.created_by, users);
            // console.log("Creator ID:", creatorId); // Verify creatorId is valid

            if (!creatorId) {
                console.error("Invalid creator ID");
                return;
            }

            const projectDetails = await getProjectDetails(project.id, creatorId);
            await fetchProjectActivity(project.id);
            // console.log(project.id)

            if (projectDetails) {
                setProjectDetail(projectDetails);
                setTimeout(() => {
                    document.querySelector('#projectDetailModalTrigger').click();
                }, 0)
            } else {
                console.error("Project details not found");
            }
        } catch (error) {
            console.error("Error fetching project details:", error);
        }
    };

    const fetchProjectActivity = async (projectId) => {
        try {
            const json = JSON.stringify({ "project_id": projectId, "authorization": getCookie("token") });
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_API}/api.php?action=get_activities`,
                JSON.stringify({ params: json }),
                {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }
            );

            if (response.data.success === "true") {
                // Assuming each activity has a `timestamp` property
                const sortedActivities = response.data.activities.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                setProjectActivities(sortedActivities);
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const formatProjectId = (id) => {
        return id < 10 ? `0${id}` : id;
    };

    const extractFileName = (filePath) => {
        // Extract the file name
        const fileName = filePath.split('/').pop();

        // Remove everything before the first underscore, including numbers and dots
        return fileName.replace(/^[^_]*_/, '');
    };

    const downloadFile = (filePath) => {
        const fileName = extractFileName(filePath);
        const link = document.createElement('a');
        const filePathNew = process.env.REACT_APP_BASE_API + filePath
        // console.log(filePathNew)
        link.href = filePathNew;
        link.download = fileName;
        link.click();
    };


    return (
        <>
            <div ref={modalRef} className="modal roboto-regular fade" id="projectModal" tabIndex={-1} aria-labelledby="projectModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="projectModalLabel">Add Project</h1>
                            <button id='addModalCloseBtn' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {/* Project Name Input */}
                            <div className="mt-2">
                                <h5 className="login-label-text-css text-start mb-2">Project Name</h5>
                                <input id="projectName" className="w-100 border focus-visible-none p-2 px-3 rounded-2 bg-transparent" type="text" />
                            </div>

                            {/* Project Description Input */}
                            <div className="mt-4">
                                <h5 className="login-label-text-css text-start mb-2">Project Description</h5>
                                <textarea id="projectDesc" style={{ resize: 'none' }} className="w-100 border focus-visible-none p-3 rounded-2 bg-transparent" />
                            </div>

                            {/* Due Date and Categories */}
                            <div className="mt-4 d-sm-flex justify-content-between gap-4">
                                <div className="w-100 mb-4 mb-sm-0">
                                    <h5 className="login-label-text-css text-start mb-2">Due Date</h5>
                                    <input id="dueDate" className="w-100 border focus-visible-none p-2 px-3 rounded-2 bg-transparent" type="date" />
                                </div>

                                <div className="w-100 mb-4 mb-sm-0">
                                    <h5 className="login-label-text-css text-start mb-2">Categories</h5>
                                    <div className="dropdown">
                                        <button
                                            className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {selectedCategory}
                                            <IoChevronDownOutline />
                                        </button>

                                        <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                            {categories.map((category) => (
                                                <li key={category.id}>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCategorySelect(category);
                                                        }}
                                                    >
                                                        {category.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                </div>
                                <div className="w-100">
                                    <h5 className="login-label-text-css text-start mb-2">Priority</h5>
                                    <div className="dropdown">
                                        <button className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {priority}
                                            <IoChevronDownOutline />
                                        </button>
                                        <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                            {['High', 'Medium', 'Low'].map((level) => (
                                                <li key={level}>
                                                    <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); setPriority(level); }}>
                                                        {level}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 w-100">
                                <div className="mb-3">
                                    <h5 className="login-label-text-css text-start mb-2">Upload Files</h5>
                                    <input
                                        type="file"
                                        id="fileUpload"
                                        className="form-control"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div>

                                {selectedFiles.length > 0 && (
                                    <div className="mt-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className='text-start'>
                                                    <th>S.No</th>
                                                    <th>Filename</th>
                                                    {/* <th>Type</th> */}
                                                    <th>Size (KB)</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedFiles.map((file, index) => (
                                                    <tr key={index} className='text-start'>
                                                        <td>{index + 1}</td>
                                                        <td className='text-start'>{file.name}</td>
                                                        {/* <td>{file.type || 'Unknown'}</td> */}
                                                        <td>{(file.size / 1024).toFixed(2)}</td>
                                                        <td>
                                                            <button
                                                                className="border-0 bg-transparent"
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                <LuTrash2 className='text-danger' />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </div>

                            {/* Progress Slider */}
                            <div className="mt-4">
                                <h5 className="login-label-text-css text-start mb-2">Project Progress</h5>
                                <div className="d-flex align-items-center gap-3">
                                    <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={progress}
                                        onChange={handleProgressChange}
                                        className="form-range w-100"
                                    />
                                    <span>{progress}%</span>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h5 className="login-label-text-css text-start mb-3">Add Team Member</h5>
                                <div className="align-items-center gap-3 project-add-modal-member-div-css">
                                    {/* {users.map((user) => (
                                        <div className="form-check d-flex align-items-center" key={user.id}>
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                value={user.id}
                                                id={`flexCheckDefault${user.id}`}
                                                onChange={() => handleUserSelect(user.id)}
                                            />
                                            <label className="form-check-label d-flex login-label-text-css" htmlFor={`flexCheckDefault${user.id}`}>
                                                <img src={user.profile_picture} alt="" />
                                                <h5 className='login-label-text-css text-start mb-0'> {user.name} </h5>
                                            </label>
                                        </div>
                                    ))} */}
                                    {users.map((user) => (
                                        <div className="form-check d-flex align-items-center mb-3" key={user.id}>
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                value={user.id}
                                                id={`flexCheckDefault${user.id}`}
                                                onChange={() => handleUserSelect(user.id)}
                                            />
                                            <label
                                                className="form-check-label d-flex login-label-text-css align-items-center"
                                                htmlFor={`flexCheckDefault${user.id}`}
                                            >
                                                {user.profile_picture ? (
                                                    <img style={{
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        objectFit: 'cover',
                                                        fontSize: '12px'
                                                    }} className="me-2" src={process.env.REACT_APP_BASE_API + user.profile_picture} alt={user.name} />
                                                ) : (
                                                    <div
                                                        style={{
                                                            backgroundColor: getColorFromHash(user.name || user.id.toString()),
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            objectFit: 'cover',
                                                            fontSize: '12px',
                                                        }}
                                                        className='me-2'
                                                    >
                                                        {user.name.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                                <h5 className="login-label-text-css text-start mb-0">{user.name}</h5>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={isRequested}
                                onClick={() => {
                                    const projectName = document.querySelector('#projectName').value;
                                    const projectDesc = document.querySelector('#projectDesc').value;
                                    const dueDate = document.querySelector('#dueDate').value;
                                    const categoryId = categories.find(cat => cat.name === selectedCategory)?.id || '';
                                    addProject(projectName, projectDesc, dueDate, categoryId, progress);
                                }}
                            >
                                {isRequested ? 'Adding Project...' : " Add Project"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {editingProject && (
                <div className="modal roboto-regular fade" id="projectEditModal" tabIndex={-1} aria-labelledby="projectEditModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="projectEditModalLabel">Edit Project</h1>
                                <button id='editModalCloseBtn' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="mt-2">
                                    <h5 className="login-label-text-css text-start mb-2">Project Name</h5>
                                    {/* <input id="projectName" className="w-100 border focus-visible-none p-2 px-3 rounded-2 bg-transparent" type="text" disabled /> */}
                                    <p className='mb-0 text-start'>
                                        {editingProject.name}
                                    </p>
                                </div>
                                <div className="mt-4 d-sm-flex justify-content-between gap-4">
                                    <div className="w-100 mb-3 mb-sm-0">
                                        <h5 className="login-label-text-css text-start mb-2">Due Date</h5>
                                        <input id="editDueDate" className="w-100 border focus-visible-none p-2 px-3 rounded-2 bg-transparent" type="date" />
                                    </div>
                                    <div className="w-100">
                                        <h5 className="login-label-text-css text-start mb-2">Categories</h5>
                                        <div className="dropdown">
                                            <button className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {selectedCategory} <IoChevronDownOutline />
                                            </button>
                                            <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                                {categories.map((category) => (
                                                    <li key={category.id}>
                                                        <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleCategorySelect(category); }}>
                                                            {category.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 w-100">
                                    <h5 className="login-label-text-css text-start mb-2">Priority</h5>
                                    <div className="dropdown">
                                        <button className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {editingPriority}
                                            <IoChevronDownOutline />
                                        </button>
                                        <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                            {['High', 'Medium', 'Low'].map((level) => (
                                                <li key={level}>
                                                    <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); setEditingPriority(level); }}>
                                                        {level}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h5 className="login-label-text-css text-start mb-2">Status</h5>
                                    <div className="dropdown">
                                        <button className="w-100 border d-flex justify-content-between align-items-center bg-transparent p-2 px-3 text-start rounded-2 dropdown-toggle project-add-modal-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {editingProject?.status || 'Select Status'} <IoChevronDownOutline />
                                        </button>
                                        <ul className="dropdown-menu project-add-modal-dropdown-menu">
                                            {['pending', 'in_progress', 'completed'].map((status) => (
                                                <li key={status}>
                                                    <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleStatusChange(status); }}>
                                                        {status.replace('_', ' ')}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="mt-4 w-100">
                                    <h5 className="login-label-text-css text-start mb-2">Attachments</h5>
                                    {editingProject.files.length > 0 && (
                                        <div className="mt-3">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className='text-start'>
                                                        <th>S.No</th>
                                                        <th>Filename</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {editingProject.files.map((file, index) => (
                                                        <tr key={index} className='text-start'>
                                                            <td>{index + 1}</td>
                                                            <td className='text-start'>{file.file_path.split('/').pop()}</td>
                                                            <td>
                                                                <button className="border-0 bg-transparent" onClick={() => handleRemoveAttachment(file.file_id)}>
                                                                    <LuTrash2 className='text-danger' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <div className="mb-3">
                                        <h5 className="login-label-text-css text-start mb-2">Upload New Files</h5>
                                        <input type="file" id="editFileUpload" className="form-control" multiple onChange={handleEditFileChange} />
                                    </div>
                                </div> */}

                                <div className="mt-4 w-100">
                                    <h5 className="login-label-text-css text-start mb-2">Attachments</h5>
                                    {(editingProject.files.length > 0 || editFiles.length > 0) && (
                                        <div className="mt-3">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className='text-start'>
                                                        <th>S.No</th>
                                                        <th>Filename</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* Render previously added files */}
                                                    {editingProject.files.map((file, index) => (
                                                        <tr key={`existing-${index}`} className='text-start'>
                                                            <td>{index + 1}</td>
                                                            <td className='text-start'>{extractFileName(file.file_path)}</td>
                                                            <td>
                                                                <button className="border-0 bg-transparent" onClick={() => handleRemoveAttachment(file.file_id)}>
                                                                    <LuTrash2 className='text-danger' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {/* Render newly selected files */}
                                                    {editFiles.map((file, index) => (
                                                        <tr key={`new-${index}`} className='text-start'>
                                                            <td>{editingProject.files.length + index + 1}</td>
                                                            <td className='text-start'>{file.name}</td>
                                                            <td>
                                                                <button className="border-0 bg-transparent" onClick={() => handleRemoveEditAttachment(index)}>
                                                                    <LuTrash2 className='text-danger' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <div className="mb-3">
                                        <h5 className="login-label-text-css text-start mb-2">Upload New Files</h5>
                                        <input type="file" id="editFileUpload" className="form-control" multiple onChange={handleEditFileChange} />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <h5 className="login-label-text-css text-start mb-2">Project Progress</h5>
                                    <div className="d-flex align-items-center gap-3">
                                        <input type="range" min="0" max="100" value={progress} onChange={handleProgressChange} className="form-range w-100" />
                                        <span>{progress}%</span>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <h5 className="login-label-text-css text-start mb-2">Edit Team Member</h5>
                                    <div className="align-items-center gap-3 project-add-modal-member-div-css">
                                        {/* {users.map((user) => (
                                            <div className="form-check d-flex align-items-center mb-3" key={user.id}>
                                                <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    value={user.id}
                                                    id={`editCheckDefault${user.id}`}
                                                    checked={selectedEditUserIds.includes(user.id)}
                                                    onChange={() => handleEditUserSelect(user.id)}
                                                />
                                                <label
                                                    className="form-check-label d-flex login-label-text-css align-items-center"
                                                    htmlFor={`editCheckDefault${user.id}`}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: user.color,
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            fontSize: '12px',
                                                        }}
                                                        className="me-2"
                                                    >
                                                        {user.name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <h5 className="login-label-text-css text-start mb-0">{user.name}</h5>
                                                </label>
                                            </div>
                                        ))} */}
                                        {users.map((user) => (
                                            <div className="form-check d-flex align-items-center mb-3" key={user.id}>
                                                <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    value={user.id}
                                                    id={`editCheckDefault${user.id}`}
                                                    checked={selectedEditUserIds.includes(user.id)} // Ensure this reflects the state
                                                    onChange={() => handleEditUserSelect(user.id)} // Toggle the user ID in the state
                                                />
                                                <label className="form-check-label d-flex login-label-text-css align-items-center" htmlFor={`editCheckDefault${user.id}`}>
                                                    {user.profile_picture ? (
                                                        <img style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} className="me-2" src={process.env.REACT_APP_BASE_API + user.profile_picture} alt={user.name} />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                backgroundColor: getColorFromHash(user.name || user.id.toString()),
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                objectFit: 'cover',
                                                                fontSize: '12px',
                                                            }}
                                                            className='me-2'
                                                        >
                                                            {user.name.charAt(0).toUpperCase()}
                                                        </div>
                                                    )}
                                                    <h5 className="login-label-text-css text-start mb-0">{user.name}</h5>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {/* <button type="button" className="btn btn-primary" onClick={() => {
                                    const updatedProject = {
                                        ...editingProject,
                                        due_date: document.querySelector('#editDueDate').value,
                                        category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
                                        progress: progress,
                                        priority: editingPriority,
                                        users: users.filter(user => selectedEditUserIds.includes(user.user_id))
                                    };

                                    // Add files to the project
                                    addFilesToProject(editingProject.id, editFiles);

                                    // Remove files from the project
                                    removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));

                                    // console.log('Updated Project:', updatedProject);

                                    // Reset file states after saving changes
                                    resetFileStates();
                                    resetEditModal();
                                }}>
                                    Save Changes
                                </button> */}
                                {/* <button type="button" className="btn btn-primary" onClick={async () => {
                                    const updatedProject = {
                                        ...editingProject,
                                        due_date: document.querySelector('#editDueDate').value,
                                        category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
                                        progress: progress,
                                        priority: editingPriority,
                                        users: users.filter(user => selectedEditUserIds.includes(user.user_id))
                                    };

                                    // Add files to the project
                                    await addFilesToProject(editingProject.id, editFiles);

                                    // Remove files from the project
                                    removedFiles.forEach(fileId => removeFileFromProject(editingProject.id, fileId));

                                    // console.log('Updated Project:', updatedProject);
                                    const creatorId = await getCreatorId(editingProject.created_by, users);

                                    // Fetch updated project details
                                    await getProjectDetails(editingProject.id, creatorId);

                                    // Reset file states and close modal
                                    resetFileStates();
                                    resetEditModal();
                                }}>
                                    Save Changes
                                </button> */}

                                {/* <button type="button" className="btn btn-primary" onClick={async () => {
                                    const updatedProject = {
                                        ...editingProject,
                                        due_date: document.querySelector('#editDueDate').value,
                                        category_id: categories.find(cat => cat.name === selectedCategory)?.id || '',
                                        progress: progress,
                                        priority: editingPriority,
                                        users: users.filter(user => selectedEditUserIds.includes(user.user_id))
                                    };

                                    // Add files to the project
                                    await addFilesToProject(editingProject.id, editFiles);

                                    // Remove files from the project
                                    for (const fileId of removedFiles) {
                                        await removeFileFromProject(editingProject.id, fileId);
                                    }

                                    // console.log('Updated Project:', updatedProject);
                                    resetEditModal();
                                }}>
                                    Save Changes
                                </button> */}
                                <button type="button" className="btn btn-primary" onClick={saveProjectChanges}>
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {projectDetail && (
                <div class="modal fade roboto-regular" id="projectDetailModal" tabindex="-1" aria-labelledby="projectDetailModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="projectDetailModalLabel">#PM - {formatProjectId(projectDetail.project.id)}</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-start">
                                <div className='my-2 text-start'>
                                    <h5 className='mb-0 projectdetail-title'>{projectDetail.project.name}</h5>
                                    <div className='mb-4'>
                                        <div className=''>
                                            <p className='mb-0 text-muted'>{projectDetail.project.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-8 mb-4 mb-lg-0'>
                                        <div>
                                            <div className='mb-4 pb-4 border-bottom'>
                                                <div className=''>
                                                    <h5 className='login-label-text-css'>Project Tasks</h5>
                                                </div>
                                                <div className='mt-3'>
                                                    {projectDetail.tasks.map((task, index) => (
                                                        <div className='d-flex justify-content-between align-items-center projectdetail-tasks-div shadow-sm p-2 px-3 rounded-3 mb-2'>
                                                            <div>
                                                                <h5 className="login-label-text-css mb-0 text-muted fw-bold " htmlFor="flexCheckDefault">
                                                                    {task.task_title}
                                                                </h5>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className={`badge p-2 ${task.task_status === 'in_progress'
                                                                        ? 'bg-warning-subtle text-warning'
                                                                        : task.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                            : 'bg-danger-subtle text-danger'
                                                                        }`}
                                                                    style={{ textTransform: 'capitalize' }}
                                                                >
                                                                    <small>{task.task_status === 'completed' ? 'Completed' : task.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className=''>
                                                    <h5 className='login-label-text-css'>Project Activity</h5>
                                                </div>
                                                <div style={
                                                    {
                                                        overflowY: "scroll",
                                                        height: "400px"
                                                    }
                                                } className='mt-3 projectdetail-tasks-div rounded-3 p-2'>
                                                    {projectActivities.length > 0 ? (
                                                        projectActivities.slice(0, 10).map((activity, index) => (
                                                            <div key={index} className='d-flex justify-content-between align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='me-3'>
                                                                        <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                            <MdOutlineFilePresent />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div dangerouslySetInnerHTML={{ __html: activity.message }} className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                            {/* {activity.message} */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className=''>
                                                                        {/* <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                            <button onClick={() => downloadFile(file.file_path)} className='border-0 bg-transparent p-0'>
                                                                                <MdOutlineFileDownload />
                                                                            </button>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                        : (
                                                            <div className='d-flex align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                <div className='me-3'>
                                                                    <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                        <MdOutlineFilePresent />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <h5 className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                        No Activities
                                                                    </h5>
                                                                </div>
                                                                <div>
                                                                    {/* <span
                                                                    className={`badge p-2 ${file.task_status === 'in_progress'
                                                                        ? 'bg-warning-subtle text-warning'
                                                                        : file.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                            : 'bg-danger-subtle text-danger'
                                                                        }`}
                                                                    style={{ textTransform: 'capitalize' }}
                                                                >
                                                                    <small>{file.task_status === 'completed' ? 'Completed' : file.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                </span> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div>
                                            <div className='projectdetail-tasks-div mb-3 shadow-sm p-4 rounded-2'>
                                                <div className='border-bottom pb-3'>
                                                    <h5 className='login-label-text-css mb-0'>
                                                        Attributes
                                                    </h5>
                                                </div>
                                                <div className='pt-3'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <h5 className='login-label-text-css mb-0'>
                                                                Status
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <span
                                                                className={`badge px-2 ${projectDetail.project.status === 'in_progress'
                                                                    ? 'bg-warning-subtle text-warning'
                                                                    : projectDetail.project.status === 'completed' ? 'bg-success-subtle text-success'
                                                                        : 'bg-danger-subtle text-danger'
                                                                    }`}
                                                                style={{ textTransform: 'capitalize' }}
                                                            >
                                                                <small>{projectDetail.project.status === 'completed' ? 'Completed' : projectDetail.project.status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <h5 className='login-label-text-css mb-0'>
                                                                Priority
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <span
                                                                className={`badge px-2 ${projectDetail.project.priority === 'medium'
                                                                    ? 'bg-warning-subtle text-warning'
                                                                    : projectDetail.project.priority === 'low' ? 'bg-success-subtle text-success'
                                                                        : 'bg-danger-subtle text-danger'
                                                                    }`}
                                                                style={{ textTransform: 'capitalize' }}
                                                            >
                                                                <small>{projectDetail.project.priority === 'low' ? 'Low' : projectDetail.project.priority === "medium" ? 'Medium' : 'High'}</small>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='mt-4'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <h5 className='login-label-text-css mb-0'>
                                                                Label
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h5 className='login-label-text-css mb-0'>
                                                                None
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className='mt-4'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <h5 className='login-label-text-css mb-0'>
                                                                Creator
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h5 className='login-label-text-css mb-0'>
                                                                {projectDetail.project.created_by}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='projectdetail-tasks-div mb-3 shadow-sm p-4 rounded-2'>
                                                <div className='border-bottom pb-3'>
                                                    <h5 className='login-label-text-css mb-0'>
                                                        Team Members
                                                    </h5>
                                                </div>
                                                <div className='mt-4'>

                                                    <div className="position-relative">
                                                        <div className="d-flex profile-image-stack">
                                                            {projectDetail.project.users && Object.values(projectDetail.project.users).map((member, index) => (
                                                                <div key={index} className="project-member-profile-pic-div-css">
                                                                    {member.profile_picture ? (
                                                                        <Link to={`/profile/${encodeURIComponent(btoa(btoa(member.user_id)))}`}>
                                                                            <img data-bs-dismiss="modal" className="project-member-profile-pic-css" src={process.env.REACT_APP_BASE_API + member.profile_picture} alt={member.user_name} />
                                                                        </Link>
                                                                    ) : (
                                                                        <Link to={`/profile/${encodeURIComponent(btoa(btoa(member.user_id)))}`}>
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: getColorFromHash(member.user_name || member.user_id.toString()),
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    borderRadius: '50%',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    color: 'white',
                                                                                    fontWeight: 'bold',
                                                                                    objectFit: 'cover',
                                                                                    fontSize: '12px',
                                                                                }} data-bs-dismiss="modal"
                                                                            >
                                                                                {member.user_name.charAt(0).toUpperCase()}
                                                                            </div>
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            {/* {Object.values(projectDetail.project.users).length > 3 && (
                                                                <div className="extra-count">+{Object.values(projectDetail.project.users).length - 3}</div>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='projectdetail-tasks-div shadow-sm p-4 rounded-2'>
                                                <div className='border-bottom pb-3'>
                                                    <h5 className='login-label-text-css mb-0'>
                                                        Project Files
                                                    </h5>
                                                </div>
                                                <div className=''>

                                                    <div style={
                                                        {
                                                            overflowY: "scroll",
                                                            height: "150px"
                                                        }
                                                    } className="position-relative">

                                                        <div className='mt-4 projectdetail-tasks-div rounded-3'>
                                                            {projectDetail.project.files.length > 0 ? (
                                                                projectDetail.project.files.map((file, index) => (
                                                                    <div className='d-flex justify-content-between align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='me-3'>
                                                                                <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                    <MdOutlineFilePresent />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h5 className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                                    {extractFileName(file.file_path)}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className=''>
                                                                                <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                    <button onClick={() => downloadFile(file.file_path)} className='border-0 bg-transparent p-0'>
                                                                                        <MdOutlineFileDownload />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            )
                                                                : (
                                                                    <div className='d-flex align-items-center rounded-3 p-2 bg-white mb-2'>
                                                                        <div className='me-3'>
                                                                            <div className='projectdetail-tasks-div p-1 px-2 rounded-2'>
                                                                                <MdOutlineFilePresent />
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h5 className="login-label-text-css mb-0 " htmlFor="flexCheckDefault">
                                                                                No Attachments
                                                                            </h5>
                                                                        </div>
                                                                        <div>
                                                                            {/* <span
                                                                    className={`badge p-2 ${file.task_status === 'in_progress'
                                                                        ? 'bg-warning-subtle text-warning'
                                                                        : file.task_status === 'completed' ? 'bg-success-subtle text-success'
                                                                            : 'bg-danger-subtle text-danger'
                                                                        }`}
                                                                    style={{ textTransform: 'capitalize' }}
                                                                >
                                                                    <small>{file.task_status === 'completed' ? 'Completed' : file.task_status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                </span> */}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div >
            )}
            {/* Sidebar and Header */}
            <div className='container-fluid overflow-x-hidden roboto-regular p-0'>
                <div className="row justify-content-center">
                    <div className={`d-none d-xl-block ${sidebarCollapsed ? "col-xl-1" : "col-xl-2 "}`}>
                        <Sidebar SetSidebarCollapsed={setSidebarCollapsed} />
                    </div>
                    <div style={{ height: "100vh" }} className={`position-relative ${sidebarCollapsed ? "col-xl-11" : "col-xl-10"}`}>
                        <Header />
                        <div className="mt-3 p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="text-start">Projects</h5>
                                {!isAdmin && (
                                    <div className="d-flex align-items-center">
                                        <div className="me-3">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className={`me-3 bg-primary-subtle text-primary p-1 px-2 rounded-3 ${isGridView ? '' : 'active'
                                                        }`}
                                                    onClick={() => toggleView('list')}
                                                >
                                                    <FaList />
                                                </div>
                                                <div
                                                    className={`bg-primary-subtle text-primary p-1 px-2 rounded-3 ${isGridView ? 'active' : ''
                                                        }`}
                                                    onClick={() => toggleView('grid')}
                                                >
                                                    <FiGrid />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="me-3">
                                            <div className="dropdown">
                                                <button className="border p-2 px-3 rounded-2 text-muted dropdown-after-none d-flex align-items-center bg-transparent dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {sortCriteria ? sortCriteria.replace('-', ' ') : 'Filter'} <FaChevronDown className='ms-2' />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('default')}>Default</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('status-pending')}>status - Pending</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('status-in-progress')}>status - In Progress</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('status-completed')}>status - Completed</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('priority-low')}>Priority - Low</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('priority-medium')}>Priority - Medium</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('priority-high')}>Priority - High</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="search-box mt-3 mt-sm-0">
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control rounded"
                                                    id="search-team"
                                                    placeholder="Search..."
                                                    onChange={handleSearchChange}
                                                />
                                                <IoSearch className="uil uil-search search-icon" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {isAdmin && (
                                <div className="d-sm-flex justify-content-between">
                                    <div>
                                        <button disabled={!isAdmin || isRequested} type="button" className="d-flex align-items-center btn btn-primary" data-bs-toggle="modal" data-bs-target="#projectModal">
                                            {isRequested ? 'Adding Project...' : " Add Project"}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="me-3">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className={`me-3 bg-primary-subtle text-primary p-1 px-2 rounded-3 ${isGridView ? '' : 'active'
                                                        }`}
                                                    onClick={() => toggleView('list')}
                                                >
                                                    <FaList />
                                                </div>
                                                <div
                                                    className={`bg-primary-subtle text-primary p-1 px-2 rounded-3 ${isGridView ? 'active' : ''
                                                        }`}
                                                    onClick={() => toggleView('grid')}
                                                >
                                                    <FiGrid />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="me-3">
                                            <div className="dropdown">
                                                <button className="border p-2 px-3 rounded-2 text-muted dropdown-after-none d-flex align-items-center bg-transparent dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {sortCriteria ? sortCriteria.replace('-', ' ') : 'Filter'} <FaChevronDown className='ms-2' />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('default')}>Default</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('status-pending')}>status - Pending</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('status-in-progress')}>status - In Progress</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('status-completed')}>status - Completed</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('priority-low')}>Priority - Low</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('priority-medium')}>Priority - Medium</a></li>
                                                    <li><hr className='dropdown-divider' /></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleSortAndFilter('priority-high')}>Priority - High</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="search-box mt-3 mt-sm-0">
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control rounded"
                                                    id="search-team"
                                                    placeholder="Search..."
                                                    onChange={handleSearchChange}
                                                />
                                                <IoSearch className="uil uil-search search-icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isGridView ? (
                                <div className='row mt-5 mb-5'>
                                    {projects.map((project) => (
                                        <div className="col-xl-4 col-xxl-3 col-lg-4 col-sm-6 mb-4" key={project.id}>
                                            <div className="bg-white shadow rounded-3 p-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <div className="d-flex align-items-center">
                                                            <RxDotFilled
                                                                className="me-2"
                                                                style={{ color: project.status === 'in_progress' ? 'yellow' : project.status === 'completed' ? 'green' : 'red' }}
                                                            />
                                                            <h5 className="login-label-text-css mb-0">
                                                                {project.due_date}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="dropdown">
                                                            <button
                                                                className="border-0 bg-transparent dropdown-toggle project-add-modal-dropdown"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <BiDotsHorizontalRounded />
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {isAdmin &&
                                                                    <li>
                                                                        <div className='dropdown-item'>
                                                                            <button type="button" className="border-0 w-100 text-start text-muted bg-transparent p-0" onClick={() => handleEditProject(project)}>
                                                                                Edit Project
                                                                            </button>
                                                                            <button id="editModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectEditModal"></button>
                                                                        </div>
                                                                    </li>
                                                                }
                                                                <li>
                                                                    <div className='dropdown-item'>
                                                                        <button
                                                                            type="button"
                                                                            className="border-0 w-100 text-start text-muted bg-transparent p-0"
                                                                            onClick={() => handleProjectDetails(project)}
                                                                        >
                                                                            Project Details
                                                                        </button>
                                                                        <button id="projectDetailModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectDetailModal"></button>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='dropdown-item'>
                                                                        <Link
                                                                            // type="button"
                                                                            to={`/activity/${project.id}`}
                                                                            className="border-0 w-100 text-start text-decoration-none text-muted bg-transparent p-0"
                                                                        // onClick={() => handleProjectDetails(project)}
                                                                        >
                                                                            Project Activities
                                                                        </Link>
                                                                        {/* <button id="projectDetailModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectDetailModal"></button> */}
                                                                    </div>
                                                                </li>
                                                                {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <div className="mb-1">
                                                        <p className="fw-bold text-start mb-0 text-muted project-title-css">
                                                            <Link
                                                                // id="projectDetailModal"
                                                                to={`/tasks/${btoa(btoa(project.id))}/${btoa(btoa(getCreatorId(project.created_by, users)))}`}
                                                                // data-bs-toggle="modal"
                                                                // data-bs-target="#projectDetailModal"
                                                                className="border-0 text-start text-muted text-decoration-none bg-transparent p-0 fw-bold text-start mb-0 text-muted project-title-css"
                                                            > {project.name}
                                                            </Link>
                                                            {/* <button id="projectDetailModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectDetailModal"></button> */}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="text-start text-muted project-desc-css">
                                                            {project.description}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="position-relative">
                                                        <div className="d-flex profile-image-stack">
                                                            {project.users && Object.values(project.users).slice(0, 3).map((member, index) => (
                                                                <div key={index} className="project-member-profile-pic-div-css">
                                                                    {member.profile_picture ? (
                                                                        <Link to={`/profile/${encodeURIComponent(btoa(btoa(member.user_id)))}`}>
                                                                            <img className="project-member-profile-pic-css" src={process.env.REACT_APP_BASE_API + member.profile_picture} alt={member.user_name} />
                                                                        </Link>
                                                                    ) : (
                                                                        <Link to={`/profile/${encodeURIComponent(btoa(btoa(member.user_id)))}`} className=''>
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: getColorFromHash(member.user_name || member.user_id.toString()),
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    borderRadius: '50%',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    color: 'white',
                                                                                    fontWeight: 'bold',
                                                                                    objectFit: 'cover',
                                                                                    fontSize: '12px',
                                                                                }}
                                                                            >
                                                                                {member.user_name.charAt(0).toUpperCase()}
                                                                            </div>
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            {project.users && Object.values(project.users).length > 3 && (
                                                                <div className="extra-count">+{Object.values(project.users).length - 3}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span
                                                            className={`badge p-2 ${project.status === 'in_progress'
                                                                ? 'bg-warning-subtle text-warning'
                                                                : project.status === 'completed' ? 'bg-success-subtle text-success'
                                                                    : 'bg-danger-subtle text-danger'
                                                                }`}
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            <small>{project.status === 'completed' ? 'Completed' : project.status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-start text-muted mb-0 d-flex justify-content-between">
                                                        <strong className='me-2'><ImAttachment className='me-1' />Attachments:</strong>
                                                        {project.files ? (project.files.length < 10 ? `0${project.files.length}` : project.files.length) : '00'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            ) : (
                                <>
                                    <div className='mt-4 mb-5 text-start'>
                                        {projects.map((project, index) => (
                                            <div key={project.id} className='d-flex align-items-center justify-content-between mb-4 rounded-2 bg-white shadow-sm p-4'>
                                                <div className=''>
                                                    <div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3'>
                                                                <h5 className='login-label-text-css fw-bold mb-0'>
                                                                    {++index}
                                                                </h5>
                                                            </div>
                                                            <div>
                                                                <Link
                                                                    // id="projectDetailModal"
                                                                    to={`/tasks/${encodeURIComponent(btoa(project.id))}/${encodeURIComponent(btoa(getCreatorId(project.created_by, users)))}`}
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#projectDetailModal"
                                                                    className="border-0 text-start text-muted text-decoration-none bg-transparent p-0 fw-bold text-start mb-0 text-muted"
                                                                >
                                                                    <div>
                                                                        <h5 className='mb-0 fw-bold text-muted project-title-css'>
                                                                            <small>{project.name}</small>
                                                                        </h5>
                                                                    </div>
                                                                    <div>
                                                                        <p className='fw-bold text-body-tertiary mb-0'>
                                                                            <small>{project.description}</small>
                                                                        </p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3'>
                                                                <div className="position-relative">
                                                                    <div className="d-flex profile-image-stack">
                                                                        {project.users && Object.values(project.users).slice(0, 3).map((member, index) => (
                                                                            <div key={index} className="project-member-profile-pic-div-css">
                                                                                {member.profile_picture ? (
                                                                                    <img className="project-member-profile-pic-css" src={process.env.REACT_APP_BASE_API + member.profile_picture} alt={member.user_name} />
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            backgroundColor: getColorFromHash(member.user_name || member.user_id.toString()),
                                                                                            width: '100%',
                                                                                            height: '100%',
                                                                                            borderRadius: '50%',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                            color: 'white',
                                                                                            fontWeight: 'bold',
                                                                                            objectFit: 'cover',
                                                                                            fontSize: '12px',
                                                                                        }}
                                                                                    >
                                                                                        {member.user_name.charAt(0).toUpperCase()}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                        {project.users && Object.values(project.users).length > 3 && (
                                                                            <div className="extra-count">+{Object.values(project.users).length - 3}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='me-3'>
                                                                <span
                                                                    className={`badge p-1 px-2 ${project.status === 'in_progress'
                                                                        ? 'bg-warning-subtle text-warning'
                                                                        : project.status === 'completed' ? 'bg-success-subtle text-success'
                                                                            : 'bg-danger-subtle text-danger'
                                                                        }`}
                                                                    style={{ textTransform: 'capitalize' }}
                                                                >
                                                                    <small>{project.status === 'completed' ? 'Completed' : project.status === "in_progress" ? 'In Progress' : 'Pending'}</small>
                                                                </span>
                                                            </div>
                                                            <div className='me-3 bg-light border p-1 px-2 rounded-2'>
                                                                <p className="text-start text-muted mb-0 d-flex">
                                                                    <strong className='me-2'><ImAttachment className='' /></strong>
                                                                    {project.files ? (project.files.length < 10 ? `0${project.files.length}` : project.files.length) : '00'}
                                                                </p>
                                                            </div>
                                                            <div className=''>
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="border-0 bg-transparent dropdown-toggle project-add-modal-dropdown"
                                                                        type="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <BiDotsHorizontalRounded />
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {isAdmin &&
                                                                            <li>
                                                                                <div className='dropdown-item'>
                                                                                    <button type="button" className="border-0 w-100 text-start text-muted bg-transparent p-0" onClick={() => handleEditProject(project)}>
                                                                                        Edit Project
                                                                                    </button>
                                                                                    <button id="editModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectEditModal"></button>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        <li>
                                                                            <div className='dropdown-item'>
                                                                                <button
                                                                                    type="button"
                                                                                    className="border-0 w-100 text-start text-muted bg-transparent p-0"
                                                                                    onClick={() => handleProjectDetails(project)}
                                                                                >
                                                                                    Project Details
                                                                                </button>
                                                                                <button id="projectDetailModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectDetailModal"></button>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='dropdown-item'>
                                                                                <Link
                                                                                    // type="button"
                                                                                    to={`/activity/${project.id}`}
                                                                                    className="border-0 w-100 text-start text-decoration-none text-muted bg-transparent p-0"
                                                                                // onClick={() => handleProjectDetails(project)}
                                                                                >
                                                                                    Project Activities
                                                                                </Link>
                                                                                {/* <button id="projectDetailModalTrigger" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#projectDetailModal"></button> */}
                                                                            </div>
                                                                        </li>
                                                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
