import React, { useEffect, useState } from 'react'
import './App.css'
import axios from 'axios'
import Alert from '../../Components/Alert'
import { getCookie, setCookie, checkRole } from '../../func';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const params = useParams();
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ type: '', message: '', show: false });

    useEffect(() => {
        if (params.token !== undefined && params.token !== null && params.token.toString().trim() !== "")
            validateToken(params.token);
        else {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const validateToken = (token) => {
        if (token.trim() !== "") {
            const json = JSON.stringify({ "token": token.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=validate_token`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "false") {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });

                    setTimeout(() => {
                        // navigate(`/admin/login`, { replace: true });
                        navigate(`/login`, { replace: true });
                    }, 2000);
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Request is invalid", show: true });
        }
    }

    const resetPassword = (e) => {
        e.preventDefault();

        if (password.trim() !== "" && confirmpassword.trim() !== "" && password === confirmpassword) {
            const json = JSON.stringify({ "token": params.token, "newpassword": password.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=resetpassword`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });

                    setTimeout(() => {
                        navigate(`/`, { replace: true });
                    }, 2000);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            if (password.trim() === "" && confirmpassword.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide password and confirmation password", show: true });
            else if (password.trim() !== "" && confirmpassword.trim() !== "" && password.trim() !== confirmpassword.trim())
                handleMessage({ type: 'danger', message: "Paassword and confirmation password do not match", show: true });
            else if (password.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide password", show: true });
            else if (confirmpassword.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide confirmation password", show: true });
        }
    }

    return (
        <div className='authentication-bg min-vh-100 roboto-medium'>
            <div className="bg-overlay bg-light"></div>
            <div className="container">
                <div className="d-flex flex-column min-vh-100">
                    <div className="row justify-content-center my-auto">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card mt-5">
                                <div className="card-body">
                                    <div className="p-3">
                                        <Alert type={alert.type} message={alert.message} show={alert.show} />
                                        <div className="mb-3 text-center mb-md-4">
                                            <Link to="index.html" className="d-block auth-logo">
                                                <img src="assets/images/logo-dark.png" alt="" height={22} className="auth-logo-dark" />
                                                <img src="assets/images/logo-light.png" alt="" height={22} className="auth-logo-light" />
                                            </Link>
                                        </div>
                                        <div className="mb-4 text-center">
                                            <h5 className="mb-1">Reset Your Password</h5>
                                            <p className="text-muted">Please enter your new password</p>
                                        </div>
                                        <form onSubmit={resetPassword} className="form-horizontal mt-3">
                                            <div className="mb-3">
                                                <label className="form-label login-label-text-css d-flex" htmlFor="username">Password</label>
                                                <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" id="password" />
                                            </div>
                                            <div className="form-password mb-3 auth-pass-inputgroup">
                                                <label className="form-label login-label-text-css d-flex" htmlFor="userpassword">Confirm Password</label>
                                                <div className="position-relative">
                                                    <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control" id="confirm-password" />
                                                    <button type="button" className="btn btn-link position-absolute h-100 end-0 top-0 shadow-none" id="password-addon">
                                                        <i className="mdi mdi-eye-outline font-size-16 text-muted" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="text-center">
                                                    <button className="btn p-2 px-3 btn-primary w-100 rounded-pill fw-bold" type="submit">Set New Password</button>
                                                </div>
                                            </div>{/* end row */}
                                        </form>{/* end form */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                            <div className="col-xl-12">
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">©
                                        2024 Probic. Crafted with <i className="mdi mdi-heart text-danger" /> by Themesdesign
                                    </p>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
        </div>
    )
}

export default ResetPassword