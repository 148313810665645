import React, { useEffect, useState } from 'react'
import './App.css'
import axios from 'axios'
import Alert from '../../Components/Alert'
import { getCookie, setCookie, checkRole } from '../../func';
import { Link, useNavigate, useLocation } from "react-router-dom";

function ForgotPassword() {

    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/projects`, { replace: true });
            else
                navigate(`/projects`, { replace: true });
        }
    }, [])

    const sendResetLink = (e) => {
        e.preventDefault();

        if (email.trim() !== "") {
            const json = JSON.stringify({ "email": email.trim() });

            axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=forgotpassword`, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });
                    //console.log("token: ", response.data.token);

                    setTimeout(() => {
                        // navigate(`/admin/login`, { replace: true });
                        navigate(`/`, { replace: true });
                    }, 2000);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            handleMessage({ type: 'danger', message: "Please provide your email to reset password", show: true });
        }
    }

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 5000);
    }

    const validateUser = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation

        if (email.trim() === "") {
            console.warn("Email field is empty");
            // alert("Please provide an email.");
            handleMessage({ type: 'danger', message: 'Please provide an email.', show: true });
        } else if (!emailRegex.test(email.trim())) {
            console.error("Invalid email format");
            handleMessage({ type: 'danger', message: 'Please enter a valid email address.', show: true });
            // alert("Please enter a valid email address.");
        } else {
            console.log("Valid Email Entered:", email); // Proceed if the email format is valid
            handleMessage({ type: 'success', message: 'Email Instructions have been sent to your email.', show: true });
        }
    };

    return (
        <>
            <div className='authentication-bg min-vh-100 roboto-medium'>
                <div className="bg-overlay bg-light"></div>
                <div className="container">
                    <div className="d-flex flex-column min-vh-100">
                        <div className="row justify-content-center my-auto">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-5">
                                    <div className="card-body">
                                        <div className="p-3">
                                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                                            <div className="mb-3 text-center mb-md-4">
                                                <Link to="index.html" className="d-block auth-logo">
                                                    <img src="assets/images/logo-dark.png" alt="" height={22} className="auth-logo-dark" />
                                                    <img src="assets/images/logo-light.png" alt="" height={22} className="auth-logo-light" />
                                                </Link>
                                            </div>
                                            <div className="mb-4 text-center">
                                                <h5 className="mb-1">Forgot Your Password</h5>
                                                <p className="text-muted">Enter your email and instructions will be sent to you!</p>
                                                {/* <Alert type={'success'} message={'Enter your Email and instructions will be sent to you!'} show={true} /> */}
                                            </div>
                                            <form onSubmit={sendResetLink} className="form-horizontal mt-3">
                                                <div className="mb-3">
                                                    <label className="form-label login-label-text-css d-flex" htmlFor="username">Email</label>
                                                    <input onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" id="username" placeholder="Enter Email" />
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="text-center">
                                                        <button className="btn p-2 px-3 btn-primary w-100 rounded-pill fw-bold" type="submit">
                                                            Send Password Reset Link
                                                        </button>
                                                    </div>
                                                </div>{/* end row */}
                                            </form>{/* end form */}

                                            <div className="mt-3 text-center text-muted">
                                                <p className="mb-0 fw-bold">Remember It? <Link to="/" className="fw-medium text-decoration-none"> Signin
                                                    now </Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-xl-12">
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">©
                                        2024 Probic. Crafted with <i className="mdi mdi-heart text-danger" /> by Themesdesign
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword