import React from 'react'
import './App.css'

function Footer() {
    return (
        <>
            <div>
                <footer className="d-flex position-fixed bottom-0 footer-css-z-index bg-light flex-wrap justify-content-center align-items-center p-3 mt-4 border-top">
                    <p className="col-md-4 mb-0 text-muted text-center">© 2024 Zuztec, Inc</p>
                </footer>
            </div>
        </>
    )
}

export default Footer